import { KEYCODES } from '../utils';

document.addEventListener('DOMContentLoaded', () => {
    const catalogPopup = document.querySelector('.js-catalog-list-popup');
    const openButtons = Array.from(document.querySelectorAll('.js-catalog-list-popup-open'));
    const closeButtons = Array.from(document.querySelectorAll('.js-catalog-list-popup-close'));

    function open() {
        if (catalogPopup) {
            catalogPopup.classList.add('visible');
        }
    }

    function close() {
        if (catalogPopup) {
            catalogPopup.classList.remove('visible');
        }
    }

    function onKeydown(event: KeyboardEvent) {
        if (event.keyCode === KEYCODES.ESC) {
            close();
        }
    }

    function openCatalogPopup(this: Element) {
        if (catalogPopup) {
            open();
            document.addEventListener('keydown', onKeydown);
        }
    }

    function closeCatalogPopup(this: Element) {
        if (catalogPopup) {
            close();
            document.removeEventListener('keydown', onKeydown);
        }
    }

    openButtons.forEach((btn) => {
        btn.addEventListener('click', openCatalogPopup);
    });

    closeButtons.forEach((btn) => {
        btn.addEventListener('click', closeCatalogPopup);
    });
});
