import { axios } from './axios';
import type { BaseResponse } from '../types';
import { isIE } from '../utils/is-ie';

type ReviewsResponse = BaseResponse & {
    data: {
        reviewsHtml: string;
        loadMoreUrl?: string;
    };
};

document.addEventListener('DOMContentLoaded', () => {
    const loadMoreBtn = document.querySelector('a.js-reviews-load-more-btn') as HTMLAnchorElement | null;
    const reviewsList = document.querySelector('.js-reviews-list');

    function setLoadingState() {
        setTimeout(() => {
            if (loadMoreBtn) {
                loadMoreBtn.setAttribute('disabled', 'disabled');
            }
        }, 0);
    }

    function removeLoadingState() {
        if (loadMoreBtn) {
            loadMoreBtn.removeAttribute('disabled');
        }
    }

    async function onLoadMoreBtnClick(this: HTMLAnchorElement, event: any) {
        event.preventDefault();
        event.stopPropagation();
        const url = this.href;
        let prevBtnHtml = '';

        setLoadingState();

        if (loadMoreBtn) {
            prevBtnHtml = loadMoreBtn.innerHTML;
            loadMoreBtn.innerHTML = '<app-loader></app-loader>';
        }

        try {
            const response = await axios.get<ReviewsResponse>(url);
            const { loadMoreUrl, reviewsHtml } = response.data.data;

            if (reviewsList) {
                if (isIE) {
                    reviewsList.innerHTML += reviewsHtml || '';
                } else {
                    const template = document.createElement('template');
                    template.innerHTML = reviewsHtml;
                    reviewsList.appendChild(template.content);
                }
            }

            if (loadMoreBtn) {
                if (loadMoreUrl) {
                    loadMoreBtn.removeAttribute('hidden');
                    loadMoreBtn.setAttribute('href', loadMoreUrl);
                } else {
                    loadMoreBtn.setAttribute('hidden', 'hidden');
                }
            }
        } finally {
            removeLoadingState();

            if (loadMoreBtn && prevBtnHtml) {
                loadMoreBtn.innerHTML = prevBtnHtml;
            }
        }
    }

    if (loadMoreBtn) {
        loadMoreBtn.addEventListener('click', onLoadMoreBtnClick);
    }
});
