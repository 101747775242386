<template>
    <div class="scroller" @mousedown="onMousedown" @mouseleave="onMouseleave" @mousemove="onMousemove">
        <div ref="scroller" class="scroller__content" @wheel="onWheel">
            <slot></slot>
        </div>
        <template v-if="navigation">
            <button
                class="scroller__arrow scroller__arrow--prev"
                aria-label="Предыдущий слайд"
                :disabled="scrollLeft === 0"
                @click="navigateBack"
            >
                <icon-arr></icon-arr>
            </button>
            <button
                class="scroller__arrow scroller__arrow--next"
                aria-label="Следующий слайд"
                :disabled="scrollLeft === maxScroll"
                @click="navigateForward"
            >
                <icon-arr></icon-arr>
            </button>
        </template>
    </div>
</template>

<script>
import ArrIcon from '../svg/Arr.vue';

export default {
    components: { 'icon-arr': ArrIcon },

    props: {
        navigation: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDown: false,
            startX: 0,
            endX: 0,
            scrollLeft: 0,
            scrollWidth: 0,
            rect: null,
            itemRect: null,
            gap: 20,
        };
    },

    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
        Array.from(this.$refs.scroller.querySelectorAll('a')).forEach((link) => {
            link.addEventListener('dragstart', this.onLinkDragStart);
        });
        document.addEventListener('mouseup', this.onMouseup);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        Array.from(this.$refs.scroller.querySelectorAll('a')).forEach((link) => {
            link.removeEventListener('dragstart', this.onLinkDragStart);
        });
        document.removeEventListener('mouseup', this.onMouseup);
    },

    computed: {
        maxScroll() {
            return this.rect ? this.scrollWidth - this.rect.width : 0;
        },
    },

    methods: {
        onResize() {
            const scrollerItem = this.$refs.scroller.querySelector('.scroller__item');
            this.rect = this.$refs.scroller.getBoundingClientRect();
            this.scrollWidth = this.$refs.scroller.scrollWidth;

            if (scrollerItem) {
                this.itemRect = scrollerItem.getBoundingClientRect();
            }
        },

        onMousedown(event) {
            this.isDown = true;
            this.startX = event.pageX - this.rect.left;
            this.scrollLeft = this.$refs.scroller.scrollLeft;
        },

        onMouseleave() {
            this.isDown = false;
        },

        onMousemove(event) {
            if (!this.isDown) return;
            const x = event.pageX - this.rect.left;
            this.$refs.scroller.scrollTo({
                left: this.scrollLeft - (x - this.startX),
            });
        },

        onMouseup() {
            this.scrollLeft = this.$refs.scroller.scrollLeft;
            this.isDown = false;
            this.endX = this.scrollLeft - this.rect.left;
        },

        onWheel() {
            this.scrollLeft = this.$refs.scroller.scrollLeft;
            this.endX = this.scrollLeft - this.rect.left;
        },

        onLinkDragStart(event) {
            event.preventDefault();
        },

        navigateBack() {
            this.scrollLeft = 0;
            this.$refs.scroller.scrollTo({
                left: this.scrollLeft,
                behavior: 'smooth',
            });
        },

        navigateForward() {
            if (this.itemRect) {
                const newScrollLeft = Math.min(this.scrollLeft + this.itemRect.width + this.gap, this.maxScroll);
                this.$refs.scroller.scrollTo({
                    left: newScrollLeft,
                    behavior: 'smooth',
                });
                this.scrollLeft = newScrollLeft;
            }
        },
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

app-scroller {
    display: block;
}

.scroller {
    margin-right: calc(-1 * var(--offset-x));
    position: relative;
    cursor: grab;

    &:active {
        cursor: grabbing;
    }

    @mixin hover() {
        .scroller__arrow {
            visibility: visible;

            &:not(:disabled) {
                opacity: 1;
            }
        }
    }

    @media (pointer: fine) {
        &:hover {
            @include hover();
        }
    }
}

.scroller__arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    background-color: #fff;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease, visibility 0.2s ease;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    &:disabled {
        opacity: $btn-disabled-opacity;
    }

    [fill] {
        fill: $body-color;
    }
}

.scroller__arrow--prev {
    left: var(--offset-x);
    transform: translate(0, -50%) rotate(180deg);
}

.scroller__arrow--next {
    right: var(--offset-x);
    transform: translate(0, -50%);
}

.scroller__content {
    margin-left: calc(-1 * var(--offset-x));
    padding-left: var(--offset-x);
    padding-right: var(--offset-x);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    user-select: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.scroller__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.scroller__item {
    flex-shrink: 0;

    &:not(:last-child) {
        margin-right: 20px;
    }

    &:last-child > * {
        margin-right: var(--offset-x);
    }
}
</style>
