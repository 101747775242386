import { fetchGlide } from '../dynamic-modules';
import { withLazyLoading } from '../components/glide-enhance';
import Pagination from '../components/slider/components/Pagination';
import Navigation from '../components/slider/components/Navigation';

const easeInOutQuart = 'cubic-bezier(0.76, 0, 0.24, 1)';

document.addEventListener('DOMContentLoaded', () => {
    const container = document.querySelector('.js-detail-slider');

    if (container) {
        const slides = Array.from(document.querySelectorAll('.glide__slide'));
        const totalSlides = slides.length;
        let slider: any;
        const perView = 1;
        let isInitialized = false;
        const attemptToInit = async () => {
            if(totalSlides == 1){
                var rewing_flag = false;
            }
            else{
                var rewing_flag = true;
            }
            if (window.matchMedia('(max-width: 767px)').matches) {
                if (totalSlides >= perView && !isInitialized) {
                    isInitialized = true;
                    const { default: Glide } = await fetchGlide();
                    slider = withLazyLoading(Glide, container, {
                        type: 'slider',
                        perView,
                        focusAt: 'center',
                        gap: 30,
                        rewind: rewing_flag,
                        animationDuration: 800,
                        animationTimingFunc: easeInOutQuart,
                    });

                    slider.mount({ Pagination });
                }
            }
           
            else if(!isInitialized){
                if(slider !== undefined){
                    slider.destroy();
                    slider = null;
                    isInitialized = false;
                }

            }
        };

        attemptToInit();
        window.addEventListener('resize', attemptToInit);
    }
});
