import delegate from 'delegate';

document.addEventListener('DOMContentLoaded', () => {
    const { ym, ga } = window as any;
    ga?.('create', 'UA-111545587-1', 'auto');

    delegate(document, '[data-metrics="cart"]', 'click', (event) => {
        ga?.('send', 'event', 'add', 'add_to_basket');

        let productPrice = event.target.dataset?.price ? event.target.dataset.price : 
                            (event.target.closest('a')?.dataset?.price) ? event.target.closest('a').dataset.price : 0;
        VK?.Goal('add_to_cart', {value : productPrice});
        //fbq('track', 'AddToCart');
    });

    delegate(document, '[data-metrics="order-step-1"]', 'click', () => {
        ym?.(45733218, 'reachGoal', 'oformit_zakaz_step_1');
        ga?.('send', 'event', 'zakaz', ' oformit_step_1');
    });

    Array.from(document.querySelectorAll<HTMLFormElement>('form.js-callback-form')).forEach((form) => {
        form.addEventListener('success', () => {
            ym?.(45733218, 'reachGoal', 'obratny_zvonok');
            ga?.('send', 'event', 'call', 'obratny_zvonok');
        });
    });

    Array.from(document.querySelectorAll<HTMLFormElement>('form.js-review-form')).forEach((form) => {
        form.addEventListener('success', () => {
            ym?.(45733218, 'reachGoal', 'otziv_otpravlen');
            ga?.('send', 'event', 'form', 'otziv_otpravlen');
        });
    });

    Array.from(document.querySelectorAll<HTMLFormElement>('form.js-subscription-form')).forEach((form) => {
        form.addEventListener('success', () => {
            ym?.(45733218, 'reachGoal', 'zapros_check_lista');
            ga?.('send', 'event', 'zakaz', 'check_list');
        });
    });
});
