import { axios } from './axios';
import popups from './popups';
import type { BaseResponse } from '../types';

type DeliveryResponse = BaseResponse & {
    data: {
        title?: string;
        icon?: string;
        date?: string;
        price?: string;
        period?: string;
    }[];
};

const API = {
    GET_DELIVERY_DATA: `/local/ajax/delivery/delivery.php`,
};

document.addEventListener('DOMContentLoaded', () => {
    const deliveries = Array.from(document.querySelectorAll('.js-delivery-city'));

    deliveries.forEach((el) => {
        const contentList = el.querySelector('.js-delivery-info-item');
        const input = el.querySelector('.js-delivery-city-input');
        const deliveryPopupToggler = el.querySelector('.js-catalog-detail-devilery-popup-toggler');

        if (input) {
            input.addEventListener('choice', async ({ detail }: any) => {
                const cityId = detail[0].triggerElement?.querySelector('[data-city-id]')?.dataset.cityId;
                const cityName = detail[0].triggerElement?.querySelector('.suggestion-link__title')?.textContent;

                if (cityId) {
                    // Закрываем модалку
                    const popupElement = document.querySelector('[data-lit-popup="catalog-delivery-geolocation"]');
                    const popup = popups.getInstanceByElement(popupElement);
                    popup?.close();

                    contentList?.classList.add('is-loading');

                    // Меняем текст на кнопке, которой открыли модалку
                    if (deliveryPopupToggler && cityName) {
                        deliveryPopupToggler.textContent = cityName;
                    }

                    try {
                        const { data } = await axios.get<DeliveryResponse>(API.GET_DELIVERY_DATA, {
                            params: { cityId, action: 'getDeliveryInfo' },
                        });

                        if (data.success) {
                            if (data.data && contentList) {
                                contentList.innerHTML = data.data
                                    .map((item) => {
                                        if (el.closest('.js-catalog-delivery-city')) {
                                            // If catalog detail
                                            return `
                                                <li class="catalog-detail-delivery-list__item">
                                                    <div class="catalog-detail-delivery-item">
                                                    ${
                                                        item.icon
                                                            ? `
                                                            <div class="catalog-detail-delivery-item__icon">
                                                                ${item.icon}
                                                            </div>
                                                    `
                                                            : ''
                                                    }
                                                        <div class="catalog-detail-delivery-item__text">
                                                            ${item.title}
                                                        </div>
                                                        ${
                                                            item.period
                                                                ? `
                                                            <div class="catalog-detail-delivery-item__date">
                                                                ${item.period}
                                                            </div>
                                                        `
                                                                : ''
                                                        }
                                                    </div>
                                                </li>
                                            `;
                                        }

                                        return `
                                            <li class="delivery-info-list__item">
                                                <div class="delivery-info-item">
                                                    ${
                                                        item.icon
                                                            ? `
                                                        <div class="delivery-info-item__icon">
                                                            ${item.icon}
                                                        </div>
                                                    `
                                                            : ''
                                                    }
                                                    <div class="h5 delivery-info-item__text">
                                                        ${item.title}
                                                    </div>
                                                    <div class="delivery-info-item__secondary">
                                                        ${item.period || ''}
                                                        ${item.period && item.price ? ', ' : ''}
                                                        ${item.price || ''}
                                                    </div>
                                                </div>
                                            </li>
                                        `;
                                    })
                                    .join('');
                            }
                        } else {
                            throw new Error(data.message);
                        }
                    } catch (err) {
                        console.error(err);
                        if (contentList) {
                            contentList.innerHTML = err.message;
                        }
                    } finally {
                        contentList?.classList.remove('is-loading');
                    }
                }
            });
        }
    });
});
