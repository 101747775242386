import './Collapse.scss';
import { LitElement } from 'lit-element';
import * as Cookies from 'js-cookie';

export interface Collapse {
    collapsed: boolean;
    toggler: HTMLElement | null;
    togglerText: HTMLElement | null;
    collapsedText?: string | null;
    expandedText?: string | null;
    content: HTMLElement | null;
    ro?: ResizeObserver;
    timer?: NodeJS.Timeout;
}

export class Collapse extends LitElement {
    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);

        this.collapsed = !!this.getAttribute('collapsed');

        if ('ResizeObserver' in window) {
            this.ro = new ResizeObserver((entries, observer) => {
                entries.forEach((entry) => {
                    observer.unobserve(entry.target);
                    this.setUIState();
                });
            });
        }
    }

    static get properties() {
        return {
            id: {
                type: String,
            },
            collapsed: {
                type: Boolean,
                reflect: true,
            },
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();

        this.toggler = this.renderRoot.querySelector<HTMLElement>('[data-collapse-toggler]');
        this.togglerText = this.renderRoot.querySelector<HTMLElement>('[data-collapse-toggler-text]');
        this.content = this.renderRoot.querySelector<HTMLElement>('[data-collapse-content]');

        if (this.toggler) {
            this.collapsedText = this.toggler.dataset.collapsedText;
            this.expandedText = this.toggler.dataset.expandedText;

            if (this.id && !this.toggler.getAttribute('aria-controls')) {
                this.toggler.setAttribute('aria-controls', `${this.id}`);
            }

            this.toggler.addEventListener('click', this.toggle);
        }

        if (this.content) {
            if (!this.content.id && this.id) {
                this.content.id = this.id;
            }

            if (this.ro) {
                const images = Array.from(this.content.querySelectorAll('img'));
                images.forEach((img) => this.ro?.observe(img));
            }
        }

        this.setUIState();

        if (this.content) {
            this.content.style.height = this.collapsed ? '0px' : '';
        }
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        super.attributeChangedCallback(name, oldValue, newValue);

        if (name === 'collapsed') {
            this.setUIState();
            this.dispatchEvent(new Event(typeof newValue === 'string' ? 'collapse' : 'expand', { composed: true }));
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.content = null;

        if (this.toggler) {
            this.toggler.removeEventListener('click', this.toggle);
            this.toggler = null;
        }

        this.togglerText = null;
        this.ro?.disconnect();
    }

    setUIState() {
        clearTimeout(this.timer);
        if (this.content) {
            this.content.style.height = `${this.content.scrollHeight}px`;
            if (this.collapsed) {
                requestAnimationFrame(() => {
                    setTimeout(() => {
                        if (this.content) {
                            this.content.style.height = '0px';
                        }
                    }, 1);
                });
            } else {
                this.content.addEventListener('transitionend', this.onTransitionEnd, { once: true });
                this.timer = setTimeout(
                    () => this.content?.removeEventListener('transitionend', this.onTransitionEnd),
                    500,
                );
            }
        }

        this.toggler?.setAttribute('aria-expanded', `${!this.collapsed}`);

        if (this.togglerText) {
            if (this.collapsedText && this.expandedText) {
                this.togglerText.textContent = this.collapsed ? this.collapsedText : this.expandedText;
            }
        }
    }

    onTransitionEnd() {
        if (this.content) {
            this.content.style.height = '';
        }
    }

    toggle(event: any) {
        event.preventDefault();
        this.collapsed = !this.collapsed;

        if(this.toggler) {
            if(this.toggler?.getAttribute('data-filterid')) {
                let filterId = +this.toggler.getAttribute('data-filterid'),
                    filterCookies = Cookies.get('openFilters') ? JSON.parse(Cookies.get('openFilters') ) : [];
                if(!this.collapsed) {
                    filterCookies.push(filterId);
                } else {
                    filterCookies = filterCookies.filter(id => id !== filterId)
                }
                Cookies.set('openFilters', [...new Set(filterCookies)], { expires: 31 });
            }
        }
    }
}
