<template>
    <div class="loader" role="status">
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
        >
            <circle cx="22" cy="22" r="18" stroke-width="5" stroke="#53a17e" />
            <circle
                cx="22"
                cy="22"
                r="18"
                stroke-width="5"
                stroke="#f7d154"
                class="loader__circle"
            />
        </svg>
        <div class="visually-hidden">{{ text }}</div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Загрузка...',
        },
    },
};
</script>

<style lang="scss">
$path-length: 112.36856079101562px;

app-loader {
    display: inline-block;
    width: 44px;
    height: 44px;
}

.loader {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.loader__circle {
    animation: loader 2s ease infinite;
}

@keyframes loader {
    0% {
        stroke-dasharray: $path-length $path-length;
        stroke-dashoffset: $path-length;
    }

    50% {
        stroke-dasharray: $path-length $path-length;
        stroke-dashoffset: 0;
    }

    51% {
        stroke-dasharray: -$path-length -$path-length;
        stroke-dashoffset: 0;
    }

    100% {
        stroke-dasharray: $path-length $path-length;
        stroke-dashoffset: -$path-length;
    }
}
</style>
