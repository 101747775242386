<template>
    <transition v-if="notificationsArr.length > 0" name="fade">
        <div class="cart-notifications">
            <div class="cart-notification__top">
                <div v-if="isMobile" class="cart-notification__icon">
                    <icon-cart></icon-cart>
                </div>
                <div class="h4 cart-notification__title">Товар добавлен в корзину</div>
                <!-- <button
                    class="cart-notification__remove-btn"
                    aria-label="Скрыть уведомление"
                    @click="() => removeNotification(key)"
                >
                    <icon-cross></icon-cross>
                </button> -->
            </div>
            <div v-if="!isMobile" class="cart-notifications-list">
                <transition :key="notification.id" v-for="notification in notificationsArr" name="fade">
                    <div class="cart-notification">
                        <div class="cart-notification-content">
                            <div class="cart-notification__left">
                                <img :src="notification.img" class="img-fluid" :alt="notification.title" />
                            </div>
                            <div class="cart-notification__right">
                                <div class="cart-notification-item__title">
                                    {{ notification.title }}
                                </div>
                                <div class="cart-notification-item__amount">Количество: {{ notification.amount }}</div>
                                <div class="cart-notification-item__prices">
                                    <div v-if="notification.price" class="cart-notification-item__price">
                                        {{ notification.price }}
                                    </div>
                                    <div v-if="notification.priceOld" class="cart-notification-item__price-outdated">
                                        {{ notification.priceOld }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <a v-if="!isMobile" href="/personal/cart/" class="btn btn-primary cart-nofitication__link"
                >Перейти в корзину</a
            >
        </div>
    </transition>
</template>

<script>
import { dispatcher } from '../../../inits/dispatcher';
import CrossIcon from '../svg/Cross.vue';
import CartIcon from '../svg/Cart.vue';

const TIMEOUT_MS = 4500;

export default {
    components: { 'icon-cross': CrossIcon, 'icon-cart': CartIcon },

    data() {
        return {
            notifications: {},
            notificationsArr: [],
            isMobile: window.matchMedia('(max-width: 1024px)').matches,
        };
    },

    created() {
        dispatcher.on('add-to-cart-complete', (data) => this.addNotification(data));

        window.addEventListener('resize', () => {
            this.isMobile = window.matchMedia('(max-width: 1024px)').matches;
        });
    },

    methods: {
        setNotificationArr() {
            this.notificationsArr = Object.keys(this.notifications).map((key) => {
                const _data = this.notifications[key].data;
                return {
                    id: key,
                    title: _data[0].title,
                    img: _data[0].img,
                    amount: _data.length,
                    price: _data[0].price,
                    priceOld: _data[0].priceOld,
                };
            });
        },

        addNotification(data) {
            if (this.notifications[data.id]) {
                this.notifications[data.id].data.unshift(data);
                clearTimeout(this.notifications[data.id].timeout);
                this.notifications[data.id].timeout = setTimeout(() => this.removeNotification(data.id), TIMEOUT_MS);
            } else {
                this.notifications[data.id] = {
                    data: [data],
                    timeout: setTimeout(() => this.removeNotification(data.id), TIMEOUT_MS),
                };
            }

            this.setNotificationArr();
        },

        removeNotification(id) {
            delete this.notifications[id];
            this.setNotificationArr();
        },
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.cart-notifications {
    position: fixed;
    z-index: 5;
    top: 30px;
    right: var(--offset-x);
    background-color: #fff;
    border-radius: 16px;
    padding: 30px;
    width: calc(100% - var(--offset-x) * 2);
    max-width: 450px;
    max-height: calc(100% - 60px);
    box-shadow: 0 0 30px 0 rgba(#000, 0.65);
    overflow: hidden;
    contain: content;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        top: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        padding: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.cart-notifications-list {
    @include scrollbar();
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.cart-notification {
    &:not(:last-child) {
        margin-bottom: 30px;

        @media screen and (max-width: 1440px) {
            margin-bottom: 20px;
        }
    }

    @media screen and (min-width: 2100px) {
        max-width: 510px;
    }
}

.cart-notification__top {
    padding-bottom: 14px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 26px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
        padding: 15px;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 0;
    }
}

.cart-notification__title {
    font-weight: 700;

    @media screen and (max-width: 1024px) {
        font-weight: 600;
    }
}

.cart-notification__remove-btn {
    @include clickable();
    margin-left: 15px;
    width: 20px;
    height: 20px;

    svg {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}

.cart-notification-content {
    display: flex;
}

.cart-notification__left {
    flex: 0 0 106px;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.cart-notification__right {
    flex: 1 1 auto;
    padding-left: 20px;

    @media screen and (max-width: 1024px) {
        padding-left: 0;
    }
}

.cart-notification-item__title {
    margin-bottom: 6px;
}

.cart-notification-item__amount {
    font-size: 14px;
    color: $color-black-50;
}

.cart-notification-item__prices {
    margin-top: 24px;
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 1024px) {
        margin-top: 5px;
    }
}

.cart-notification-item__price {
    font-size: responsive 18px 26px;
    font-range: 1280px 1920px;
    font-weight: 700;
    line-height: 1;

    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}

.cart-notification-item__price-outdated {
    margin-left: 16px;
    line-height: 1;
    display: inline-block;
    @include price-line-through();

    @media screen and (max-width: 767px) {
        font-size: 12px;
    }
}

.cart-nofitication__link {
    width: 100%;
    margin-top: 26px;
}

.cart-notification__icon {
    width: 20px;
    margin-right: 13px;
    flex-shrink: 0;
    position: relative;
    top: -1px;

    svg {
        width: 100%;
        height: auto;
    }
}
</style>
