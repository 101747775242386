import Axios from 'axios';
import serialize from 'form-serialize';
import delegate from 'delegate';
import debounce from 'lodash.debounce';

import type { BaseResponse } from '../types';
import masks, { thousandsSeparator } from './masks';
import { getOffsetTop } from '../utils/get-offset-top';
import { lazyload } from './lazy-load';

type CatalogResponse = BaseResponse & {
    data: {
        title: string;
        catalogHtml: string;
        filterHtml: string;
        filterResetBtnHtml: string;
        pagination: string;
        crumbs: string;
        newUrl?: string;
        action?: string;
    };
};

type CatalogLoadMoreResponse = BaseResponse & {
    data: {
        catalogHtml: string;
        newUrl?: string;
        pagination: string;
    };
};

const { CancelToken } = Axios;
let cancelActualRequest: any;

document.addEventListener('DOMContentLoaded', () => {
    const links = Array.from(document.querySelectorAll<HTMLAnchorElement>('a.js-catalog-link'));
    const form = document.querySelector<HTMLFormElement>('form.js-catalog-filter')!;
    const catalogList = document.querySelector('.js-catalog-list');
    const catalogPagination = document.querySelector('.js-catalog-pagination');
    async function onLoadMoreBtnClick(event: any) {
        const target = event.delegateTarget as HTMLButtonElement;
        const rect = target.getBoundingClientRect();
        const prevHtml = target.innerHTML;
        target.disabled = true;
        Object.assign(target.style, { width: `${rect.width}px`, height: `${rect.height}px` });
        target.innerHTML = '<app-loader class="add-to-cart-loader"></app-loader>';

        try {
            const { data } = await Axios.get<CatalogLoadMoreResponse>(target.dataset.url || '', { 'params': { AJAX_REQUEST: 'Y', ncc: 1 } });
            if (catalogList && data.data.catalogHtml) {
                const template = document.createElement('template');
                template.innerHTML = data.data.catalogHtml;
                catalogList.appendChild(template.content);
            }

            if (catalogPagination && data.data.pagination) {
                catalogPagination.innerHTML = data.data.pagination;
                let categories_tags = document.querySelectorAll(".category-tags");
                if (typeof categories_tags[1] !== "undefined") {
                    categories_tags[1].remove();
                }
            }

            if (data.data.newUrl) {
                window.history.pushState(null, '', data.data.newUrl);
            }
        } finally {
            target.innerHTML = prevHtml;
            Object.assign(target.style, { width: '', height: '' });
            target.disabled = false;
            lazyload.update();
        }
    }

    delegate(document, '.js-catalog-load-more-btn', 'click', onLoadMoreBtnClick);

    if (!form) {
        return;
    }

    const filterContent = form.querySelector('.js-catalog-filter-fields');
    const resetButtonContainer = form.querySelector('.js-catalog-filter-reset-btn-container');
    const catalogTitle = document.querySelector('.js-catalog-title');
    const catalog = document.querySelector('.js-catalog');
    const catalogFilterBlock = document.querySelector('.js-catalog-filter-block');
    const breadcrumbs = document.querySelector('.js-breadcrumbs-list');
    let priceMinInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-min');
    let priceMaxInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-max');
    const filterOpenButtons = Array.from(document.querySelectorAll('.js-catalog-filter-open-btn'));
    const filterCloseButtons = Array.from(document.querySelectorAll('.js-catalog-filter-close-btn'));
    const submitBtn = document.querySelector('.js-catalog-filter-submit-btn');
    const catalogSection = document.querySelector('.js-catalog-section');
    const catalogAvalPopup = Array.from(document.querySelectorAll('.js-catalog-aval'));
    let timer: NodeJS.Timeout;

    const clearInputs = (formEl: HTMLFormElement) => {
        Array.from(
            formEl.querySelectorAll<HTMLInputElement>('[name]:not([type="submit"]):not([type="reset"])'),
        ).forEach((input) => {
            if (input.type === 'checkbox') {
                input.checked = false;
            } else if (input.type !== 'hidden') {
                input.value = '';
            }
            input.dispatchEvent(new Event('blur'));
        });
    };

    function openFilter() {
        if (catalogFilterBlock) {
            catalogFilterBlock.classList.add('catalog-filter-block--opened');
            document.body.classList.add('no-scroll');

            if (catalogSection) {
                window.scrollTo({ top: getOffsetTop(catalogSection), behavior: 'auto' });
            }
        }
    }

    function closeFilter() {
        if (catalogFilterBlock) {
            catalogFilterBlock.classList.remove('catalog-filter-block--opened');
            document.body.classList.remove('no-scroll');
        }
    }

    submitBtn?.addEventListener('click', closeFilter);

    const initialSortingSelect = document.querySelector<HTMLSelectElement>('select.js-catalog-sorting');

    if (initialSortingSelect) {
        const sortingSelectSlots = Array.from(
            document.querySelectorAll<HTMLElement>('.js-catalog-filter-sorting-slot'),
        );
        sortingSelectSlots.forEach((slot) => {
            slot.appendChild(initialSortingSelect.cloneNode(true));
        });
    }

    const sortingSelects = Array.from(document.querySelectorAll<HTMLSelectElement>('select.js-catalog-sorting'));

    function setLoadingState() {
        setTimeout(() => {
            clearTimeout(timer);

            timer = setTimeout(() => {
                if (catalog) {
                    catalog.classList.add('loading');
                }
            }, 200);
        }, 0);
    }

    function removeLoadingState() {
        setTimeout(() => {
            clearTimeout(timer);

            if (catalog) {
                catalog.classList.remove('loading');
            }
        }, 0);
    }

    function serializeSortingSelect() {
        return initialSortingSelect ? { [initialSortingSelect.name]: initialSortingSelect.value } : {};
    }

    function getFormData() {
        if (form) {
            const catalogPriceInputs = Array.from(
                form.querySelectorAll<HTMLInputElement>('input.js-catalog-price-input'),
            );
            const prevValues = catalogPriceInputs.map((el) => el.value);

            catalogPriceInputs.forEach((el) => {
                el.value = el.value.replace(/\s*/g, '');
            });

            const formData = serialize(form, { hash: true });

            catalogPriceInputs.forEach((el, i) => {
                el.value = prevValues[i];
            });
            return formData;
        }

        return {};
    }

    async function fetchData(url: string, serializeForm = true) {
        if (cancelActualRequest) {
            cancelActualRequest();
        }

        setLoadingState();
        try {
            const response = await Axios.get<CatalogResponse>(url, {
                params: {
                    ...(serializeForm ? getFormData() : {}),
                    ...(serializeForm ? serializeSortingSelect() : {}),
                    AJAX_REQUEST: 'Y',
                },
                cancelToken: new CancelToken((c) => {
                    cancelActualRequest = c;
                }),
            });
            const {
                catalogHtml,
                filterHtml,
                filterResetBtnHtml,
                pagination,
                title,
                crumbs,
                newUrl,
                action
            } = response.data.data;
            if (catalogList) {
                catalogList.innerHTML = catalogHtml || '';
            }

            if (filterContent) {
                masks.destroy(filterContent);
                filterContent.innerHTML = filterHtml;
                priceMinInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-min');
                priceMaxInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-max');
                masks.init(filterContent);
            }

            if (resetButtonContainer) {
                resetButtonContainer.innerHTML = filterResetBtnHtml;
            }


            if (catalogTitle) {
                catalogTitle.textContent = title;
            }

            if (catalogPagination) {
                catalogPagination.innerHTML = pagination;
            }

            if (breadcrumbs) {
                breadcrumbs.innerHTML = crumbs;
            }

            if (newUrl) {
                window.history.pushState(null, title || '', newUrl);
            }

            if (title) {
                document.title = title;
            }

            if (action) {
                form.action = action;
            }
        } catch (err) {
            if (!Axios.isCancel(err)) {
                throw err;
            }
        } finally {
            removeLoadingState();
            lazyload.update();
            let el1 = document.querySelector('.app-content')
            let el2 = document.querySelector('.category-tags')
            let el3 = document.querySelector('.js-catalog-section')
            let categories_tags = document.querySelectorAll(".category-tags");
            let zverushki_tags = document.querySelectorAll(".zverushki-tags");
            let zverushki_tags_mobile = document.querySelectorAll(".mobile-zver");
            if (typeof categories_tags[1] !== "undefined") {
                categories_tags[1].remove();
            }
            if (typeof zverushki_tags[1] !== "undefined" && typeof zverushki_tags_mobile[1] !== "undefined") {
                zverushki_tags[1].remove();
                zverushki_tags_mobile[1].remove();
            } else {
                if (typeof zverushki_tags[0] !== "undefined" && typeof zverushki_tags_mobile[0] !== "undefined") {
                    zverushki_tags[0].remove();
                    zverushki_tags_mobile[0].remove();
                }
                let pagination = document.querySelector(".js-catalog");
                if (typeof zverushki_tags[0] !== "undefined" && typeof zverushki_tags_mobile[0] !== "undefined") {
                    pagination.after(zverushki_tags[0]);
                    zverushki_tags[0].after(zverushki_tags_mobile[0]);
                }
                el1.insertBefore(el2, el3);
                let section = document.querySelectorAll('.js-zver-container');
                if (typeof section[1] !== "undefined") {
                    section[1].remove();
                }
            }
            window.initRangeSliders();
            if(zverushki_tags && zverushki_tags[0]) {
                zverushki_tags[0].remove();
            }

        }
    }

    function onChange() {
        if (window.matchMedia('(min-width: 768px)').matches) {
            fetchData(form.action);
        }
    }

    function resetFilter() {
        setTimeout(() => {
            if (form) {
                clearInputs(form);
                fetchData(form.action);
            }
        }, 1);
    }

    function onFilterSubmit(event: any) {
        event.preventDefault();
        fetchData(form.action);
    }

    const debouncedOnChange = debounce(onChange, 700);

    function onInputChange() {
        if (cancelActualRequest) {
            cancelActualRequest();
        }

        debouncedOnChange();
    }

    function formattedValue(str: string) {
        return parseFloat((str || '0').replace(new RegExp(thousandsSeparator, 'g'), ''));
    }

    function onMinPriceChange(event: any) {
        const target = event.delegateTarget as HTMLInputElement;
        if (priceMaxInput && target.value.trim().length > 0) {
            const imask = masks.getInstanceByElement(priceMaxInput);

            if (imask && imask.typedValue) {
                const min = formattedValue(target.value);
                imask.typedValue = Math.max(min, imask.typedValue);
                imask.updateValue();
            }
        }
    }

    function onMaxPriceChange(event: any) {
        const target = event.delegateTarget as HTMLInputElement;
        if (priceMinInput && target.value.trim().length > 0) {
            const imask = masks.getInstanceByElement(priceMinInput);

            if (imask && imask.typedValue) {
                const max = formattedValue(target.value);
                imask.typedValue = Math.min(max, imask.typedValue);
                imask.updateValue();
            }
        }
    }

    function setActiveLinkTree(el: Element) {
        el.classList.add('active');
        const parentLi = el
            .closest('.catalog-categories-list__item')
            ?.parentElement?.closest('.catalog-categories-list__item');
        if (parentLi) {
            const link = parentLi.querySelector('.js-catalog-link');
            if (link) {
                setActiveLinkTree(link);
            }
        }
    }

    if (form) {
        delegate(form, 'input.js-catalog-price-min', 'change', onMinPriceChange);
        delegate(form, 'input.js-catalog-price-max', 'change', onMaxPriceChange);
        form.addEventListener('submit', onFilterSubmit);
        form.addEventListener('reset', resetFilter);
    }


    if (links) {
        links.forEach((link) => {
            link.addEventListener('click', async (event) => {
                event.preventDefault();
                event.stopPropagation();
                let el2 = document.querySelector('.category-tags')
                if(el2 !== null) {
                   el2.remove();
                }
                closeFilter();
                await fetchData(link.href, false);
                links.forEach((el) => {
                    el.classList.remove('active');
                });
                setActiveLinkTree(link);
                let nextSibling = (event.target as HTMLElement).nextElementSibling;
                if (nextSibling?.classList.contains('collapse-arr-toggler')) {
                    (nextSibling as HTMLElement).click();
                }
                const links_category = Array.from(document.querySelectorAll<HTMLAnchorElement>('a.category-link'));
            });
        });
    }
    if (typeof links_category !== 'undefined') {
        links_category.forEach((link) => {
            link.addEventListener('click', async (event) => {
                event.preventDefault();
                event.stopPropagation();
                let el2 = document.querySelector('.category-tags')
                if(el2 !== null) {
                   el2.remove();
                }
                await fetchData(link.href, false);
                links.forEach((el) => {
                    el.classList.remove('active');
                });
                setActiveLinkTree(link);
                let nextSibling = (event.target as HTMLElement).nextElementSibling;
                if (nextSibling?.classList.contains('collapse-arr-toggler')) {
                    (nextSibling as HTMLElement).click();
                }
            });
        });
    }

    if (form) {
        delegate(form, 'input', 'change', onInputChange);
    }

    sortingSelects.forEach((select) => {
        select.addEventListener('change', (event: any) => {
            let val = event.target.value;
            sortingSelects.forEach((select) => {
                if(select.value !== val) {
                    select.value = val;
                }
            })
            fetchData(form.action);
        });
    });

    filterOpenButtons.forEach((btn) => {
        btn.addEventListener('click', openFilter);
    });

    filterCloseButtons.forEach((btn) => {
        btn.addEventListener('click', closeFilter);
    });

    catalogAvalPopup.forEach((btn) => {
        btn.addEventListener('click', (event) => {
            let productId = event.target.dataset.productid;
            document.getElementById('product-id-aval').value = productId;
        });
    });
});
