import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';
import serialize from 'form-serialize';

const SELECTOR = '.js-ajax-form';
const map = new WeakMap<HTMLFormElement, Record<string, any>>();

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');

    if (successMessage) {
        successMessage.innerHTML = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');

    if (failureMessage) {
        failureMessage.innerHTML = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.remove('form-messages--hidden');
    }
};

const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.add('form-messages--hidden');
    }
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector('input.js-form__check-val') as HTMLInputElement | null;
    if (checkInput) {
        checkInput.value = '';
    }
};

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];

    if (forms.length > 0) {
        const [{ default: createFormSender }, { default: createValidator, clearMessages }] = await Promise.all([
            fetchAjaxFormSender(),
            fetchValidator(),
        ]);

        forms.forEach((form) => {
            const popup = form.closest('.lit-popup');
            const validator = createValidator(form);
            const sender = createFormSender(form, {
                shouldClearInputs: form.dataset.keepvalue != 'true',
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: ({ success, message }) => {
                    if (success) {
                        showSuccessMessage(form, message);
                    } else {
                        showFailureMessage(form, message);
                    }
                },
                onError: () => {
                    showFailureMessage(form, 'Произошла ошибка при отправке формы');
                },
                onComplete: () => {
                    if(form.dataset.setrrapi) {
                        let formdata = serialize(form, { hash: true });
                        (window["rrApiOnReady"] = window["rrApiOnReady"] || []).push(function() {
                            try {
                                rrApi.subscribeOnItemBackInStock(formdata.email,formdata.id);
                                rrApi.setEmail(formdata.email);
                                rrApi.welcomeSequence(formdata.email);
                            } catch (e) {}
                        })
                    }
                    if(form.dataset.reloadafter) {
                        location.reload();
                    }

                    clearMessages(form);
                    showFormMessages(form);

                    popup?.addEventListener(
                        'close-complete',
                        () => {
                            setTimeout(() => {
                                hideFormMessages(form);
                            }, 300);
                        },
                        { once: true },
                    );
                },
            });
            map.set(form, sender);

            form.addEventListener('submit', (event) => {
                event.preventDefault();
                const isFormValid = validator.validate();
                if (isFormValid) {
                    sender.send();
                }
            });
        });
    }
}

function destroy(container: Element | Document = document) {
    //
}

function getInstanceByElement(element?: HTMLFormElement | null) {
    return element ? map.get(element) : undefined;
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
