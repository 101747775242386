import delegate from 'delegate';
import Axios from 'axios';
import type { BaseResponse } from '../types';
import { dispatcher } from './dispatcher';

type ApplyCouponResponse = BaseResponse & {
    data: {
        couponsHtml: string;
    };
};

const API = {
    APPLY_COUPON: `/local/ajax/sale/basket.php`,
};

function init() {
    const couponsListContainer = document.querySelector<HTMLElement>('.js-coupons-list-container');

    delegate(document, '.js-apply-coupon', 'click', async (event: any) => {
        const target = event.delegateTarget as HTMLElement;
        const id = target.dataset.couponId;

        try {
            const { data } = await Axios.post<ApplyCouponResponse>(API.APPLY_COUPON, { id, action: 'setCouponPage' });

            if (data.message) {
                dispatcher.dispatch('notification', { message: data.message });
            }

            if (data.success) {
                if (couponsListContainer) {
                    couponsListContainer.innerHTML = data.data.couponsHtml;
                }
            } else {
                throw new Error(data.message);
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    });
}

const _module = { init };

export default _module;
