import Axios from 'axios';
import serialize from 'form-serialize';
import delegate from 'delegate';
import debounce from 'lodash.debounce';

import type { BaseResponse } from '../types';
import masks, { thousandsSeparator } from './masks';
import { getOffsetTop } from '../utils/get-offset-top';
import { lazyload } from './lazy-load';

type CatalogResponse = BaseResponse & {
    data: {
        title: string;
        catalogHtml: string;
        filterHtml: string;
        pagination: string;
        loadMoreUrl?: string;
        crumbs: string;
        newUrl?: string;
        action?: string;
    };
};

const { CancelToken } = Axios;
let cancelActualRequest: any;

document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector<HTMLFormElement>('form.js-catalog-search-filter')!;

    if (!form) {
        return;
    }

    const catalog = document.querySelector('.js-catalog-search');
    const catalogList = document.querySelector('.js-catalog-list');
    const catalogListFilter = document.querySelector('.js-catalog-filter-list');
    const catalogPagination = document.querySelector('.js-search-pagination');
    const filterContent = document.querySelector('.js-catalog-filter-fields');
    let paginationBtn = Array.from(document.querySelectorAll('.js-page-search-link'));
    const url = '/local/ajax/search-page.php';
    let priceMinInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-min');
    let priceMaxInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-max');
    let pageInput = form.querySelector<HTMLInputElement>('input.js_current_page');
    let sortInput = form.querySelector<HTMLInputElement>('input.js_sort');
    const filterOpenButtons = Array.from(document.querySelectorAll('.js-catalog-filter-open-btn'));
    const filterCloseButtons = Array.from(document.querySelectorAll('.js-catalog-filter-close-btn'));
    const submitBtn = document.querySelector('.js-catalog-filter-submit-btn');
    let timer: NodeJS.Timeout;

    const clearInputs = (formEl: HTMLFormElement) => {
        Array.from(
            formEl.querySelectorAll<HTMLInputElement>('[name]:not([type="submit"]):not([type="reset"])'),
        ).forEach((input) => {
            if (input.type === 'checkbox') {
                input.checked = false;
            } else if (input.type !== 'hidden') {
                input.value = '';
            }
            input.dispatchEvent(new Event('blur'));
        });
    };

    function openFilter() {
        if (catalogListFilter) {
            catalogListFilter.classList.add('catalog-filter-block--opened');
            document.body.classList.add('no-scroll');

            if (catalogSection) {
                window.scrollTo({ top: getOffsetTop(catalogSection), behavior: 'auto' });
            }
        }
    }

    function closeFilter() {
        if (catalogListFilter) {
            catalogListFilter.classList.remove('catalog-filter-block--opened');
            document.body.classList.remove('no-scroll');
        }
    }

    submitBtn?.addEventListener('click', closeFilter);

    const initialSortingSearchSelect = document.querySelector<HTMLSelectElement>('select.js-search-sorting');

    if (initialSortingSearchSelect) {
        const sortingSelectSearchSlots = Array.from(
            document.querySelectorAll<HTMLElement>('.js-catalog-filter-sorting-slot'),
        );
        sortingSelectSearchSlots.forEach((slot) => {
            slot.appendChild(initialSortingSearchSelect.cloneNode(true));
        });
    }

    const sortingSelects = Array.from(document.querySelectorAll<HTMLSelectElement>('select.js-search-sorting'));

    function setLoadingState() {
        setTimeout(() => {
            clearTimeout(timer);

            timer = setTimeout(() => {
                if (catalog) {
                    catalog.classList.add('loading');
                }
            }, 200);
        }, 0);
    }

    function removeLoadingState() {
        setTimeout(() => {
            clearTimeout(timer);

            if (catalog) {
                catalog.classList.remove('loading');
            }
        }, 0);
    }

    function serializeSortingSelect() {
        return {};
    }

    function getFormData() {
        if (form) {
            const catalogPriceInputs = Array.from(
                form.querySelectorAll<HTMLInputElement>('input.js-catalog-price-input'),
            );
            const prevValues = catalogPriceInputs.map((el) => el.value);

            catalogPriceInputs.forEach((el) => {
                el.value = el.value.replace(/\s*/g, '');
            });

            const formData = serialize(form, { hash: true });

            catalogPriceInputs.forEach((el, i) => {
                el.value = prevValues[i];
            });

            return formData;
        }

        return {};
    }

    async function fetchData(url: string, serializeForm = true) {
        if (cancelActualRequest) {
            cancelActualRequest();
        }

        setLoadingState();
        try {
            const response = await Axios.get<CatalogResponse>(url, {
                params: {
                    ...(serializeForm ? getFormData() : {})
                },
                cancelToken: new CancelToken((c) => {
                    cancelActualRequest = c;
                }),
            });
            const {catalogHtml, filterHtml, pagination} = response.data.data;

            if (catalogList) {
                catalogList.innerHTML = catalogHtml || '';
            }
            if (catalogPagination) {
                catalogPagination.innerHTML = pagination || '';
                paginationBtn = Array.from(document.querySelectorAll('.js-page-search-link'));
                initPaginator();
            }

            if (filterContent) {
                masks.destroy(filterContent);
                filterContent.innerHTML = filterHtml;
                priceMinInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-min');
                priceMaxInput = form.querySelector<HTMLInputElement>('input.js-catalog-price-max');
                masks.init(filterContent);

                pageInput = form.querySelector<HTMLInputElement>('input.js_current_page');
                sortInput = form.querySelector<HTMLInputElement>('input.js_sort');
            }
        } catch (err) {
            if (!Axios.isCancel(err)) {
                throw err;
            }
        } finally {
            removeLoadingState();
            lazyload.update();
        }
    }

    function onChange() {
        fetchData(url);
    }

    const debouncedOnChange = debounce(onChange, 700);

    function onInputChange() {
        if (cancelActualRequest) {
            cancelActualRequest();
        }

        debouncedOnChange();
    }

    function formattedValue(str: string) {
        return parseFloat((str || '0').replace(new RegExp(thousandsSeparator, 'g'), ''));
    }

    function onMinPriceChange(event: any) {
        const target = event.delegateTarget as HTMLInputElement;
        if (priceMaxInput && target.value.trim().length > 0) {
            const imask = masks.getInstanceByElement(priceMaxInput);

            if (imask) {
                const min = formattedValue(target.value);
                imask.typedValue = Math.max(min, imask.typedValue);
                imask.updateValue();
            }
        }
    }

    function onMaxPriceChange(event: any) {
        const target = event.delegateTarget as HTMLInputElement;
        if (priceMinInput && target.value.trim().length > 0) {
            const imask = masks.getInstanceByElement(priceMinInput);

            if (imask) {
                const max = formattedValue(target.value);
                imask.typedValue = Math.min(max, imask.typedValue);
                imask.updateValue();
            }
        }
    }

    function setActiveLinkTree(el: Element) {
        el.classList.add('active');
        const parentLi = el
            .closest('.catalog-categories-list__item')
            ?.parentElement?.closest('.catalog-categories-list__item');
        if (parentLi) {
            const link = parentLi.querySelector('.js-catalog-link');
            if (link) {
                setActiveLinkTree(link);
            }
        }
    }

    if (form) {
        delegate(form, 'input.js-catalog-price-min', 'change', onMinPriceChange);
        delegate(form, 'input.js-catalog-price-max', 'change', onMaxPriceChange);
        delegate(form, 'input', 'change', onInputChange);
    }

    function initPaginator() {
        paginationBtn.forEach((btn) => {
            btn.addEventListener('click', () => {
                const parentLi = btn.closest('.page-item');
                let page = btn.dataset.page;
                pageInput.value = page;

                fetchData(url);
            });
        });
    }

    sortingSelects.forEach((select) => {
        select.addEventListener('change', (event: any) => {
            sortInput.value = select.value;
            sortingSelects.forEach((select1) => {
                if(select1.value !== sortInput.value) {
                    select1.value = sortInput.value;
                }
            })
            fetchData(url);
        });
    });

    filterOpenButtons.forEach((btn) => {
        btn.addEventListener('click', openFilter);
    });

    filterCloseButtons.forEach((btn) => {
        btn.addEventListener('click', closeFilter);
    });

    initPaginator();
});
