import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';
import popups from './popups';

async function clearForm(form: HTMLFormElement) {
    const [{ clearInputs }, { clearMessages, clearInputErrors }] = await Promise.all([
        fetchAjaxFormSender(),
        fetchValidator(),
    ]);

    const inputs = Array.from(
        form.querySelectorAll<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
            'input[name]:not([type="submit"]):not([type="reset"]), select[name], textarea[name]',
        ),
    );

    clearInputs(inputs);
    inputs.forEach((input) => clearInputErrors(input));
    clearMessages(form);
}

function init() {
    const forms = Array.from(
        document.querySelectorAll<HTMLFormElement>('form.js-ajax-form[data-clear-on-popup-close]'),
    );

    forms.forEach((form) => {
        const popupElement = form.closest('[data-lit-popup]');
        const popup = popups.getInstanceByElement(popupElement);
        if (popup) {
            popup.on('close-complete', () => {
                clearForm(form);
            });
        }
    });
}

const _module = { init };

export default _module;
