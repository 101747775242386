import popups from './popups';

function init() {
    const closeOnSuccessForms = Array.from(
        document.querySelectorAll('form.js-close-on-success-form'),
    ) as HTMLFormElement[];

    closeOnSuccessForms.forEach((form) => {
        let timer: NodeJS.Timeout;
        const closeOnSuccessPopup = form.closest('[data-lit-popup]');

        if (!closeOnSuccessPopup) return;

        const litPopupInstance = popups.getInstanceByElement(closeOnSuccessPopup);

        if (litPopupInstance) {
            form.addEventListener('success', () => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    litPopupInstance.close();
                }, 5000);

                closeOnSuccessPopup.addEventListener(
                    'close-complete',
                    () => {
                        clearTimeout(timer);
                    },
                    { once: true },
                );
            });
        }
    });
}

const _module = { init };

export default _module;
