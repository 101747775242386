import Axios from 'axios';
import {API as CartAPI, getCartAmount, setCartAmount} from './cart.ts'
import {API as FavouritesAPI, addToFavouritesById, removeFromFavouritesById, ACTIVE_CLASS} from './favourites.ts'

document.addEventListener('DOMContentLoaded', () => {
    window.digiLayer.getWishList();
    window.digiLayer.getCart();
});

window.digiLayer =  {

    cartState: {},
    whishlistState: [],

    getCart: function(){
        return new Promise(async (resolve) => {
            const { data } = await Axios.post(CartAPI.ADD_TO_CART, { action: 'getBasketData' });
            let cartState = Object.entries(data.data).reduce((prev, current) => {
                prev[current[0]] = current[1].QUANTITY;
                return prev;
            }, {});
            this.cartState = cartState;
            return resolve(cartState)
        })
    },

    addToCart: function (id, amount) {
        return new Promise(async (resolve) => {
            const {data} = await Axios.post(CartAPI.ADD_TO_CART, {id, amount, action: 'addToBasket'});
            if (data.success)
            {
                this.cartState = data.cart.basketData
                setCartAmount(Object.values(this.cartState).length);
            }
            return resolve(true)
        })
    },

    removeFromCart: function (id) {
        return new Promise(async (resolve) => {
            const newAmount = this.cartState[id] === 1 || this.cartState[id] === undefined ? 0 : this.cartState[id] - 1
            const action = newAmount === 0 ? 'removeFromBasket' : 'updateBasketAmount'
            const { data } = await Axios.post(CartAPI.ADD_TO_CART, {
                id,
                amount: newAmount,
                action
            });
            if( data.success )
            {
                if( id in this.cartState )
                {
                    if( action === 'removeFromBasket' )
                        delete this.cartState[id]
                    else
                        this.cartState[id] = data.data.product_amount
                }
                setCartAmount(Object.values(this.cartState).length);
            }
            return resolve(true)
        })
    },

    getWishList: function(){
        return new Promise(async (resolve) => {
            const { data } = await Axios.post(FavouritesAPI.GET_FAVOURITES, { action: 'favorite_get' });
            if( data.success )
            {
                let whishlistState = data.data.favorites || []
                this.whishlistState = whishlistState;
                return resolve(whishlistState)
            }
        })
    },

    addToWishList: function (id, amount) {
        return new Promise(async (resolve) => {
            const { data } = await addToFavouritesById(id);
            if( data.success )
            {
                this.whishlistState = Array.from(new Set(this.whishlistState.concat(id)))
                document.querySelectorAll('.js-add-to-favourites[data-catalog-id="'+id+'"]').forEach(item => {
                    item.classList.add(ACTIVE_CLASS)
                })
            }
            return resolve(true)
        })
    },

    removeFromWishList: function (id) {
        return new Promise(async (resolve) => {
            const { data } = await removeFromFavouritesById(id);
            if( data.success )
            {
                this.whishlistState = this.whishlistState.filter(item => item !== id)
                document.querySelectorAll('.js-add-to-favourites[data-catalog-id="'+id+'"]').forEach(item => {
                    item.classList.remove(ACTIVE_CLASS)
                })
            }
            return resolve(true)
        })
    }
}
