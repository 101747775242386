import Axios from 'axios';
import type { BaseResponse } from '../types';
import popups from './popups';

type CancelOrderResponse = BaseResponse & {
    data: {
        html: string;
    };
};

const API = {
    CANCEL_ID: `${PUBLIC_PATH}php/cancel_order.php`,
};

function init() {
    const cancelOrderButtons = Array.from(document.querySelectorAll<HTMLElement>('.js-cancel-order-btn'));
    const payButtons = Array.from(document.querySelectorAll<HTMLAnchorElement>('a.js-pay'));
    const cancelOrderPopupElement = document.querySelector<HTMLElement>('[data-lit-popup="cancel-order"]');
    const cancelPopup = popups.getInstanceByElement(cancelOrderPopupElement);

    cancelOrderPopupElement?.addEventListener('open', ({ detail: { triggerElement } }: any) => {
        if (triggerElement) {
            cancelOrderButtons.forEach((btn) => {
                btn.setAttribute('data-order-id', triggerElement.dataset.orderId);
            });
        }
    });

    payButtons.forEach((btn) => {
        btn.addEventListener('click', async (event: any) => {
            event.preventDefault();

            try {
                const { data } = await Axios.get(btn.href);
                const div = document.createElement('div');
                div.innerHTML = data;
                if (btn.parentElement) {
                    btn.parentElement.insertBefore(div, btn);
                    btn.parentElement.removeChild(btn);
                }
            } finally {
                //
            }
        });
    });

    cancelOrderButtons.forEach((btn) => {
        btn.addEventListener('click', async (event: any) => {
            event.preventDefault();
            btn.setAttribute('disabled', 'disabled');

            try {
                const { data } = await Axios.post<CancelOrderResponse>(API.CANCEL_ID, { id: btn.dataset.orderId });

                if (data.success) {
                    const orderContainer = document.querySelector('.js-order-info-container');
                    if (orderContainer) {
                        orderContainer.innerHTML = data.data.html;
                        cancelPopup?.close();
                    }
                } else {
                    throw new Error(data.message || 'Не удалось отменить заказ.');
                }
            } catch (err) {
                //
            } finally {
                btn.removeAttribute('disabled');
            }
        });
    });
}

(window as any).selectSdekPVZ = (address_pvz: string, address: string, worktime: string) => {
    const sdekPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="sdek"]'),
        sdekPopup = popups.getInstanceByElement(sdekPopupMap);
    sdekPopup?.close();

    document.dispatchEvent(new CustomEvent("sdek_new.selected", {
        detail: {
            'Address' : address,
            'WorkTime' : worktime,
            'pvzAdress' : address_pvz
        }
    }));
};

(window as any).selectPecPVZ = (address_pvz: string, address: string) => {
    const pecPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="pec"]'),
        pecPopup = popups.getInstanceByElement(pecPopupMap);
    pecPopup?.close();

    document.dispatchEvent(new CustomEvent("pec_new.selected", {
        detail: {
            'Address' : address,
            'pvzAdress' : address_pvz
        }
    }));
};

(window as any).selectPost = (postal: string, street: string, house: string) => {
    const popupMap = document.querySelector<HTMLElement>('[data-lit-popup="post"]'),
        popup = popups.getInstanceByElement(popupMap);
    popup?.close();
    $('#soa-property-4').val(postal).addClass('floated-labels--active');
    $('#soa-property-20').val(street).addClass('floated-labels--active');
    $('#soa-property-21').val(house).addClass('floated-labels--active');
};

(window as any).selectPSTM = (address_pvz: string, address: string, type: string, pvzDateText: string, pvzDate: string) => {
    const pstmPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="pstm"]'),
        pstmPopup = popups.getInstanceByElement(pstmPopupMap);
    pstmPopup?.close();

    document.dispatchEvent(new CustomEvent("pstm.selected", {
        detail: {
            'Address' : address,
            'Type' : type,
            'pvzAdress' : address_pvz,
            'pvzDateText' : pvzDateText,
            'pvzDate' : pvzDate,
        }
    }));
};

(window as any).autocomplete = (inp: HTMLElement, city: string) => {
    let currentFocus;

    inp.addEventListener("input", function(e) {
        var a, b, i, val = this.value;
        closeAllLists();
        if (!val || val.length < 3) { return false;}
        currentFocus = -1;

        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");

        this.parentNode.appendChild(a);

        Axios
            .post('/local/ajax/dadataAddress.php?AJAX_REQUEST=Y', { q: city + ' ' + val})
            .then((ans) => {
                /*for each item in the array...*/
                let arr = ans.data.data;
                for (i = 0; i < arr.length; i++) {
                    b = document.createElement("DIV");
                    b.innerHTML += arr[i].markup;
                    a.appendChild(b);
                }

                const sdekSearchItem =  Array.from(document.querySelectorAll<HTMLElement>('#'+ this.id + "autocomplete-list .autocomplete-link"));
                sdekSearchItem.forEach((item) => {
                    item.addEventListener('click', async (event: any) => {
                        let coords = item.dataset.coordinates,
                            title = item.dataset.title;
                        inp.value = title;
                        if (typeof(myMap) !== 'undefined') {
                            myMap.setCenter(JSON.parse(coords), 14);
                        }
                        closeAllLists();
                    });
                });
            })
    });

    function closeAllLists(elmnt) {
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
};

(window as any).selectSsPVZ = (CODE: string) => {
    const ssPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="sspvz"]');
    const ssPopup = popups.getInstanceByElement(ssPopupMap);
    ssPopup?.close();

    document.dispatchEvent(new CustomEvent("ssPvz.selected", {
        detail: {
            'code' : CODE,
        }
    }));
};

(window as any).SSPvzMap = (lat: string, lon: string, city: string) => {
    const sspvzPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="sspvz"]');
    const sspvzPopup = popups.getInstanceByElement(sspvzPopupMap);
    const sspvzMap = document.querySelector<HTMLElement>('#sspvz-map');
    const sspvzList = document.querySelector<HTMLElement>('#sspvz-list');
    const sspvzSearch = document.querySelector<HTMLElement>('#sspvz-search');
    sspvzPopup?.open();
    sspvzMap.innerHTML = '';
    sspvzList.innerHTML = '';
    Axios
        .post('/local/ajax/ss_pvz.php', { lat: lat, lon: lon })
        .then((ans) => {
            ymaps.ready(function () {
                if (typeof (myMap) !== 'undefined') {
                    myMap.destroy();
                }
                (window as any).myMap = new ymaps.Map('sspvz-map', {
                    center: [lat, lon],
                    zoom: 10,
                    controls: []
                });
                let listContent = '';
                for (var i = ans.data.length - 1; i >= 0; i--) {
                    let pvz = ans.data[i];
                    //pvz.location.city = pvz.location.city.replace(/"/g, "&quot;");
                    let pvz_address = pvz.ADDRESS.replace(/"/g, "&quot;");
                    let balloonContent = '<div class="sdek-baloon"><div class="sdek-baloon__title">' + pvz['NAME'] + '</div><div class="sdek-baloon__address">' + pvz['ADDRESS'] + '</div><div class="sdek-baloon__worktime">' + pvz['WORKING_STR'] + '</div><div class="sdek-baloon__btn"><button class="btn-sm btn-primary" onclick="selectSsPVZ(\'' + pvz['CODE'] + '\')">Выбрать</button></div></div>'
                    listContent += '<div class="sspvz-list" data-code="' + pvz['CODE'] + '"><div class="sdek-list__title">' + pvz['METRO'] + '</div><div class="sdek-list__address">' + pvz['ADDRESS'] + '</div></div>'
                    let splitedCoor = pvz['MAP'].split(',');
                    myMap.geoObjects
                        .add(new ymaps.Placemark([splitedCoor[0], splitedCoor[1]], {
                            balloonContent: balloonContent,
                            name: pvz['CODE']
                        }, {
                            preset: 'islands#icon',
                            iconColor: '#53a17e',
                            hideIconOnBalloonOpen: false,
                            openBalloonOnClick: true,
                            balloonOffset:[0,-24]
                        }))
                }
                sspvzList.innerHTML = listContent;
                const sspvzListItem =  Array.from(document.querySelectorAll<HTMLElement>('.sspvz-list'));
                sspvzListItem.forEach((item) => {
                    item.addEventListener('click', async (event: any) => {
                        let n = item.dataset.code;
                        ymaps.geoQuery(myMap.geoObjects).each(function(pm) {
                            if (pm.properties.get('name') == n) {
                                pm.balloon.open();
                                pm.options.set({iconColor : '#53a17e'});
                            } else {
                                pm.options.set({iconColor : '#a0a0a0'});
                            }
                        });
                    });
                });

                /*sspvzSearch.innerHTML = '<input type="text" id="sspvz-search-input" class="form-control" placeholder="Поиск по карте" />';
                const searchInput = document.querySelector<HTMLElement>('#sspvz-search-input');
                if (typeof(searchInput) != 'undefined' && searchInput != null) {
                    autocomplete(document.getElementById("sspvz-search-input"), city);
                }*/
            });
        });
}
(window as any).SemenaPEC = (cdekId: string, lat: string, lon: string, city: string) => {
    const pecPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="pec"]'),
        pecPopup = popups.getInstanceByElement(pecPopupMap),
        pecMap = document.querySelector<HTMLElement>('#sdek-map'),
        pecList = document.querySelector<HTMLElement>('#pec-list'),
        pecSearch = document.querySelector<HTMLElement>('#sdek-search');
    pecPopup?.open();
    pecMap.innerHTML = '';
    pecList.innerHTML = '';
    Axios
        .post('/local/ajax/pec_pvz.php', {cdekId : cdekId, lat: lat, lon: lon, city: city})
        .then((ans) => {
            ymaps.ready(function() {
                if (typeof (myMap) !== 'undefined') {
                    myMap.destroy();
                }
                (window as any).myMap = new ymaps.Map('pec-map', {
                    center: [lat, lon],
                    zoom: 10,
                    controls: []
                });
                console.log(ans);
                let listContent = '';
                for (var i = ans.data.data.length - 1; i >= 0; i--) {
                    let pvz = ans.data.data[i];
                    pvz.location.address = pvz.location.address.replace(/"/g, "&quot;");
                    let pvz_address = pvz.location.address+" #"+pvz.code + '#';
                    let balloonContent = '<div class="sdek-baloon"><div class="sdek-baloon__title">' + pvz['name'] + '</div><div class="sdek-baloon__address">' + pvz['location']['address'] + '</div><div class="sdek-baloon__btn"><button class="btn-sm btn-primary" onclick="selectPecPVZ(\'' + pvz_address + '\', \''+pvz['location']['address']+'\')">Выбрать</button></div></div>'
                    listContent += '<div class="pec-list" data-code="' + pvz['code'] + '"><div class="sdek-list__title">' + pvz['name'] + '</div><div class="sdek-list__address">' + pvz['location']['address'] + '</div></div>'
                    myMap.geoObjects
                        .add(new ymaps.Placemark([pvz['location']['latitude'], pvz['location']['longitude']], {
                            balloonContent: balloonContent,
                            name: pvz['code']
                        }, {
                            preset: 'islands#icon',
                            iconColor: '#53a17e',
                            hideIconOnBalloonOpen: false,
                            openBalloonOnClick: true,
                            balloonOffset:[0,-24]
                        }))
                }
                pecList.innerHTML = listContent;

                pecSearch.innerHTML = '<input type="text" id="pec-search-input" class="form-control" placeholder="Поиск по карте" />';

                const pecListItem =  Array.from(document.querySelectorAll<HTMLElement>('.pec-list'));
                pecListItem.forEach((item) => {
                    item.addEventListener('click', async (event: any) => {
                        let n = item.dataset.code;
                        ymaps.geoQuery(myMap.geoObjects).each(function(pm) {
                            if (pm.properties.get('name') == n) {
                                pm.balloon.open();
                                pm.options.set({iconColor : '#53a17e'});
                            } else {
                                pm.options.set({iconColor : '#a0a0a0'});
                            }
                        });
                    });
                });

                const searchInput = document.querySelector<HTMLElement>('#sdek-search-input');
                if (typeof(searchInput) != 'undefined' && searchInput != null) {
                    autocomplete(document.getElementById("sdek-search-input"), city);
                }
            });
        });
}

(window as any).SemenaSDEK = (cdekId: string, lat: string, lon: string, city: string) => {
    const sdekPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="sdek"]'),
        sdekPopup = popups.getInstanceByElement(sdekPopupMap),
        sdekMap = document.querySelector<HTMLElement>('#sdek-map'),
        sdekList = document.querySelector<HTMLElement>('#sdek-list'),
        sdekSearch = document.querySelector<HTMLElement>('#sdek-search');
    sdekPopup?.open();
    sdekMap.innerHTML = '';
    sdekList.innerHTML = '';
    Axios
        .post('/local/ajax/sdek.php', { cdekId, lat, lon, city })
        .then((ans) => {
            ymaps.ready(function() {
                if (typeof(myMap) !== 'undefined') {
                    myMap.destroy();
                }
                (window as any).myMap = new ymaps.Map('sdek-map', {
                    center: ans.data.coords,
                    zoom: 10,
                    controls: []
                });
                let listContent = '';
                for (var i = ans.data.data.length - 1; i >= 0; i--) {
                    let pvz = ans.data.data[i];
                    pvz.location.address = pvz.location.address.replace(/"/g, "&quot;");
                    pvz.location.city = pvz.location.city.replace(/"/g, "&quot;");
                    let pvz_address = pvz.location.city+", "+pvz.location.address+" #S"+pvz.code;
                    let balloonContent = '<div class="sdek-baloon"><div class="sdek-baloon__title">' + pvz['name'] + '</div><div class="sdek-baloon__address">' + pvz['location']['address_full'] + '</div><div class="sdek-baloon__worktime">' + pvz['work_time'] + '</div><div class="sdek-baloon__btn"><button class="btn-sm btn-primary" onclick="selectSdekPVZ(\'' + pvz_address + '\', \''+pvz['location']['address']+'\' , \'' + pvz['work_time'] + '\')">Выбрать</button></div></div>'
                    listContent += '<div class="sdek-list" data-code="' + pvz['code'] + '"><div class="sdek-list__title">' + pvz['name'] + '</div><div class="sdek-list__address">' + pvz['location']['address_full'] + '</div></div>'
                    myMap.geoObjects
                        .add(new ymaps.Placemark([pvz['location']['latitude'], pvz['location']['longitude']], {
                            balloonContent: balloonContent,
                            name: pvz['code']
                        }, {
                            preset: 'islands#icon',
                            iconColor: '#53a17e',
                            hideIconOnBalloonOpen: false,
                            openBalloonOnClick: true,
                            balloonOffset:[0,-24]
                        }))
                }
                sdekList.innerHTML = listContent;

                sdekSearch.innerHTML = '<input type="text" id="sdek-search-input" class="form-control" placeholder="Поиск по карте" />';

                const sdekListItem =  Array.from(document.querySelectorAll<HTMLElement>('.sdek-list'));
                sdekListItem.forEach((item) => {
                    item.addEventListener('click', async (event: any) => {
                        let n = item.dataset.code;
                        ymaps.geoQuery(myMap.geoObjects).each(function(pm) {
                            if (pm.properties.get('name') == n) {
                                pm.balloon.open();
                                pm.options.set({iconColor : '#53a17e'});
                            } else {
                                pm.options.set({iconColor : '#a0a0a0'});
                            }
                        });
                    });
                });

                const searchInput = document.querySelector<HTMLElement>('#sdek-search-input');
                if (typeof(searchInput) != 'undefined' && searchInput != null) {
                    autocomplete(document.getElementById("sdek-search-input"), city);
                }
            })

        })
}

(window as any).pstm = (cdekId: string, zip: string, lat: string, lon: string, city: string, locationId: string, weight: number, podZakaz = 0) => {
    const pstmPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="pstm"]'),
        pstmPopup = popups.getInstanceByElement(pstmPopupMap),
        pstmMap = document.querySelector<HTMLElement>('#pstm-map'),
        pstmList = document.querySelector<HTMLElement>('#pstm-list'),
        pstmSearch = document.querySelector<HTMLElement>('#pstm-search');
    pstmPopup?.open();
    pstmMap.innerHTML = '';
    pstmList.innerHTML = '';

    Axios
        .post('/local/ajax/postamats.php', { cdekId : cdekId, zip: zip, lat: lat, lon: lon, city: city, locationId: locationId, weight: weight, podZakaz: podZakaz})
        .then((ans) => {
            ymaps.ready(function() {
                if (typeof(myMap) !== 'undefined') {
                    myMap.destroy();
                }
                (window as any).myMap = new ymaps.Map('pstm-map', {
                    center: ans.data.coords,
                    zoom: 10,
                    controls: []
                });
                let listContent = '';
                for (var i = ans.data.data.length - 1; i >= 0; i--) {
                    let pstm = ans.data.data[i];
                    //pstm.location.address = pstm.location.address !== '' ? pstm.location.address.replace(/"/g, "&quot;") : "";
                    //pstm.location.city = pstm.location.city.replace(/"/g, "&quot;");
                    let pstm_address = pstm.location.city+", "+pstm.location.address+" #S"+pstm.code;
                    let balloonContent = '<div class="sdek-baloon"><div class="sdek-baloon__title">' + pstm['name'] + '</div><div class="sdek-baloon__address">' + (pstm['location']['address_full'] ? pstm['location']['address_full'] : pstm['location']['address']) + '</div><div class="sdek-baloon__address">'+ pstm['description'] +'</div><div class="sdek-baloon__worktime">' + pstm['work_time'] + '</div><div class="sdek-baloon__btn"><button class="btn-sm btn-primary" onclick="selectPSTM(\'' + pstm_address + '\', \''+pstm['location']['address']+'\' , \'' + pstm['type_rus'] + '\', \'' + pstm['delivery_date_text'] + '\', \'' + pstm['delivery_date'] + '\')">Выбрать</button></div></div>'
                    listContent += '<div class="sdek-list pstm-list" data-code="' + pstm['code'] + '"><div class="sdek-list__title">' + pstm['name'] + '</div><div class="sdek-list__address">' + (pstm['location']['address_full'] ? pstm['location']['address_full'] : pstm['location']['address']) + '</div></div>'
                    myMap.geoObjects
                        .add(new ymaps.Placemark([pstm['location']['latitude'], pstm['location']['longitude']], {
                            balloonContent: balloonContent,
                            name: pstm['code']
                        }, {
                            preset: 'islands#icon',
                            iconColor: pstm['type'] === 'CDEK' ? '#53a17e' : pstm['type'] === 'AXILOG' ? '#64b441' : '#1937ff',
                            hideIconOnBalloonOpen: false,
                            openBalloonOnClick: true,
                            balloonOffset:[0,-24]
                        }))
                }
                pstmList.innerHTML = listContent;

                pstmSearch.innerHTML = '<input type="text" id="pstm-search-input" class="form-control" placeholder="Поиск по карте" />';

                const pstmListItem =  Array.from(document.querySelectorAll<HTMLElement>('.pstm-list'));
                pstmListItem.forEach((item) => {
                    item.addEventListener('click', async (event: any) => {
                        let n = item.dataset.code;
                        ymaps.geoQuery(myMap.geoObjects).each(function(pm) {
                            if (pm.properties.get('name') == n) {
                                pm.balloon.open();
                                pm.options.set({iconColor : 'red', zIndex: 600});
                            } else {
                                pm.options.set({iconColor : '#a0a0a0', zIndex: 20});
                            }
                        });
                    });
                });

                const searchInput = document.querySelector<HTMLElement>('#pstm-search-input');
                if (typeof(searchInput) != 'undefined' && searchInput != null) {
                    autocomplete(document.getElementById("pstm-search-input"), city);
                }
            })
        })
}

(window as any).post = (city: string, region: string) => {
    const postPopupMap = document.querySelector<HTMLElement>('[data-lit-popup="post"]'),
        postPopup = popups.getInstanceByElement(postPopupMap),
        postMap = document.querySelector<HTMLElement>('#post-map'),
        postList = document.querySelector<HTMLElement>('#post-list'),
        postSearch = document.querySelector<HTMLElement>('#post-search');
    postPopup?.open();
    postMap.innerHTML = '';
    postList.innerHTML = '';
    Axios
        .post('/local/ajax/post.php', {city : city, region : region})
        .then((ans) => {
            ymaps.ready(function() {
                if (typeof(myMap) !== 'undefined') {
                    myMap.destroy();
                }
                (window as any).myMap = new ymaps.Map('post-map', {
                    center: ans.data.coords,
                    zoom: 10,
                    controls: []
                });
                let listContent = '';
                for (var i = ans.data.data.length - 1; i >= 0; i--) {
                    let pvz = ans.data.data[i];

                    let postalCode = pvz.location.postal;
                    let street = pvz.location.street;
                    let house = pvz.location.house;

                    let balloonContent = '<div class="sdek-baloon"><div class="sdek-baloon__address">' + pvz['location']['address_full'] + '</div><div class="sdek-baloon__worktime">' + pvz['work_time'] + '</div><div class="sdek-baloon__btn"><button class="btn-sm btn-primary" onclick="selectPost(\'' + postalCode + '\', \''+street+'\' , \'' + house + '\')">Выбрать</button></div></div>'
                    listContent += '<div class="sdek-list post-list" data-code="' + pvz['code'] + '"><div class="sdek-list__address">' + pvz['location']['address_full'] + '</div></div>'
                    myMap.geoObjects
                        .add(new ymaps.Placemark([pvz['location']['latitude'], pvz['location']['longitude']], {
                            balloonContent: balloonContent,
                            name: postalCode
                        }, {
                            preset: 'islands#icon',
                            iconColor: '#53a17e',
                            hideIconOnBalloonOpen: false,
                            openBalloonOnClick: true,
                            balloonOffset:[0,-24]
                        }))
                }
                postList.innerHTML = listContent;

                postSearch.innerHTML = '<input type="text" id="post-search-input" class="form-control" placeholder="Поиск по карте" />';

                const postListItem =  Array.from(document.querySelectorAll<HTMLElement>('.post-list'));
                postListItem.forEach((item) => {
                    item.addEventListener('click', async (event: any) => {
                        let n = item.dataset.code;
                        ymaps.geoQuery(myMap.geoObjects).each(function(pm) {
                            if (pm.properties.get('name') == n) {
                                pm.balloon.open();
                                pm.options.set({iconColor : '#53a17e'});
                            } else {
                                pm.options.set({iconColor : '#a0a0a0'});
                            }
                        });
                    });
                });

                const searchInput = document.querySelector<HTMLElement>('#post-search-input');
                if (typeof(searchInput) != 'undefined' && searchInput != null) {
                    autocomplete(document.getElementById("post-search-input"), city);
                }
            })

        })
}

const _module = { init };

export default _module;
