import delegate from 'delegate';

function init() {
    delegate(document, '.js-product-card-badges-toggler', 'click', async (event: any) => {
        event.preventDefault();
        const badgesContainer = event.target.closest('.js-product-card-badges');
        badgesContainer?.classList.toggle('opened');
    });
}

const _module = { init };

export default _module;
