function init() {
    const menuContainer = document.querySelector('.js-menu-content');
    const cityPickerElement = document.querySelector('.js-header-city-picker');
    const iconicListElement = document.querySelector('.js-header-iconic-list');
    const callbackPhone = document.querySelector('.js-callback-phone');
    const agroBtn = document.querySelector('.js-agro-button');

    if (menuContainer) {

        if (iconicListElement) {
            const menuFromHeaderContainer = document.querySelector('.js-menu-content-from-header');
            const clone = iconicListElement.cloneNode(true) as HTMLElement;
            clone.classList.add('header-iconic-list--mobile');
            menuFromHeaderContainer?.appendChild(clone);
        }

        if (cityPickerElement) {
            const clone = cityPickerElement.cloneNode(true) as HTMLElement;
            const locationIdentifier = clone.querySelector('location-identifier');
            if (locationIdentifier) {
                clone.removeChild(locationIdentifier);
            }
            menuContainer.appendChild(clone);
        }

        if (callbackPhone) {
            menuContainer.appendChild(callbackPhone.cloneNode(true));
        }
        if (agroBtn) {
            menuContainer.appendChild(agroBtn.cloneNode(true));
        }
    }
}

const _module = { init };

export default _module;
