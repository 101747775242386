import { LitElement } from 'lit-element';
import axios from 'axios';

export class AddReviewButtonAsync extends LitElement {
    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();

        axios.get(this.dataset.action!, { params: { id: this.dataset.id, AJAX_REQUEST: 'Y' } }).then((response) => {
            this.innerHTML = response.data;
        });
    }
}
