import delegate from 'delegate';
import popups from './popups';

function init() {
    let timer: NodeJS.Timeout;

    delegate(document, '.js-image-popup', 'click', (event: any) => {
        clearTimeout(timer);
        const target = event.delegateTarget as HTMLAnchorElement;
        const image = document.querySelector<HTMLIFrameElement>('img.js-image-popup__img');
        const popup = popups.getInstanceByElement(document.querySelector('[data-lit-popup="image"]'));

        console.log(target);

        if (image && target.dataset.src) {
            image.src = target.dataset.src;
        }

        popup?.open();

        popup?.on('close-complete', () => {
            document.body.classList.remove('no-scroll');
            if (image) {
                timer = setTimeout(() => {
                    image.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
                }, 300);
            }
        });
    });
}

const _module = { init };

export default _module;
