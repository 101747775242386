import { fetchImask } from '../dynamic-modules';

const map = new WeakMap<Element, any>();
export const thousandsSeparator = ' ';

function getInstanceByElement(element: Element) {
    return map.get(element);
}

export async function initNumberMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLInputElement>('input[data-mask="number"]:not(.mask--initialized)'),
    );

    if (elements.length > 0) {
        const { default: IMask } = await fetchImask();

        elements.forEach((el) => {
            const imask = IMask(el, {
                mask: Number,
                min: el.dataset.min ? parseFloat(el.dataset.min) : -Infinity,
                max: el.dataset.max ? parseFloat(el.dataset.max) : Infinity,
                thousandsSeparator,
                scale: el.dataset.maskScale ? parseInt(el.dataset.maskScale, 10) : 2,
            });
            map.set(el, imask);
            el.classList.add('mask--initialized');
        });
    }
}

export async function initPhoneMask(input: HTMLInputElement, dialCode = '7') {
    const { default: IMask } = await fetchImask();
    input.value = input.value.replace(/^\+/, '');
    if (input.value.substring(0, 3) === '375') {
        dialCode = '375';
    }
    const imask = IMask(input, { mask: `+{${dialCode}} 000-000-00-00` });
    map.set(input, imask);

    if (dialCode === '7') {
        imask.on('accept', () => {
            imask.value = input.value.replace(/^\+7\s8/, '+7');
            imask.updateValue();
        });
    }

    input.classList.add('mask--initialized');
}

export async function initDateMask(input: HTMLInputElement) {
    const { default: IMask } = await fetchImask();
    input.value = input.value.replace(/^\+/, '');
    const imask = IMask(input, { mask: Date, max: new Date() });
    map.set(input, imask);

    input.classList.add('mask--initialized');
}

export async function destroyPhoneMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
        input.classList.remove('mask--initialized');
    }
}

async function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll('input[data-mask="phone"]:not(.mask--initialized)'),
    ) as HTMLInputElement[];

    if (elements.length > 0) {
        await fetchImask();
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}

async function initDateMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll<HTMLInputElement>('input[data-mask="date"]:not(.mask--initialized)'),
    );

    if (elements.length > 0) {
        await fetchImask();
        elements.forEach((el) => {
            initDateMask(el);
        });
    }
}

async function initCodeMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll('input[data-mask="code"]:not(.mask--initialized)'),
    ) as HTMLInputElement[];

    if (elements.length > 0) {
        const { default: IMask } = await fetchImask();

        elements.forEach((el) => {
            const imask = IMask(el, { mask: '0' });
            map.set(el, imask);
            el.classList.add('mask--initialized');
        });
    }
}

async function initIndexMasks(container: Element | Document = document) {
    const elements = Array.from(
        container.querySelectorAll('input[data-mask="index"]:not(.mask--initialized)'),
    ) as HTMLInputElement[];

    if (elements.length > 0) {
        const { default: IMask } = await fetchImask();

        elements.forEach((el) => {
            const imask = IMask(el, {
                mask: Number,
                min: el.dataset.min ? parseFloat(el.dataset.min) : -Infinity,
                max: el.dataset.max ? parseFloat(el.dataset.max) : Infinity,
            });
            map.set(el, imask);
            el.classList.add('mask--initialized');
        });
    }
}

function init(container: Element | Document = document) {
    initNumberMasks(container);
    initPhoneMasks(container);
    initCodeMasks(container);
    initIndexMasks(container);
    initDateMasks(container);
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('input[data-mask]')) as HTMLInputElement[];
    elements.forEach((el) => {
        destroyPhoneMask(el);
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
