let buttons: Element[] = [];

async function onBtnClick(this: Element) {
    if ('share' in navigator) {
        // Use native Web Share API
        const sharingElement = document.querySelector('.js-invitation-sharing');
        if (sharingElement) {
            await navigator.share({
                title: sharingElement.getAttribute('title') || undefined,
                text: sharingElement.getAttribute('text') || undefined,
                url: sharingElement.getAttribute('url') || undefined,
            });
        }

        return;
    }

    // Use fallback UI
    this.classList.toggle('active');
}

function closeOnOutsideClick(element: Element) {
    if (!element.closest('.invitation-sharing-btn-container')) {
        buttons.forEach((btn) => {
            btn.classList.remove('active');
        });
    }
}

function init() {
    buttons = Array.from(document.querySelectorAll('.js-invitation-share'));
    buttons.forEach((btn) => {
        btn.addEventListener('click', onBtnClick);
    });

    document.addEventListener('click', (event: MouseEvent) => {
        closeOnOutsideClick(event.target as Element);
    });
}

const _module = { init };

export default _module;
