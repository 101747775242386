import type { BaseResponse } from '../types';
import { axios } from './axios';

type AjaxTabResponse = BaseResponse & {
    data: {
        html: string;
    };
};

function init(container: Element | Document = document) {
    const tabs = Array.from(container.querySelectorAll<HTMLElement>('.tabs'));

    tabs.forEach((tab) => {
        const links = Array.from(tab.querySelectorAll<HTMLElement>('.tab__link'));
        const contents = Array.from(tab.querySelectorAll<HTMLElement>('.tab__content'));

        links.forEach((btn, i) => {
            btn.addEventListener('click', (event) => {
                event.preventDefault();

                if (btn.parentElement?.classList.contains('active')) {
                    return;
                }

                contents.forEach((el) => {
                    el.classList.remove('active');
                });

                contents[i].classList.add('active');

                links.forEach((link) => {
                    link.parentElement?.classList.remove('active');
                });

                btn.parentElement?.classList.add('active');

                if (btn.classList.contains('js-ajax-tab-link')) {
                    const url = btn.dataset.tabEndpoint;

                    if (btn.classList.contains('is-loaded')) {
                        if (url) {
                            contents[i].innerHTML = '<app-loader class="catalog-tab-loader"></app-loader>';
                            axios
                                .get<AjaxTabResponse>(url)
                                .then((response) => {
                                    if (response.data.success) {
                                        const { html } = response.data.data;

                                        if (html) {
                                            contents[i].innerHTML = response.data.data.html;
                                        }
                                    }
                                })
                                .finally(() => {
                                    window.history.replaceState(null, '', url);
                                });
                        }
                    } else {
                        window.history.replaceState(null, '', url);
                    }
                }
            });
        });
    });

    // if (hash) {
    //     tabs.forEach((tab) => {
    //         const links = Array.from(tab.querySelectorAll('.tab__link'));
    //         const contents = Array.from(tab.querySelectorAll('.tab__content'));

    //         links.forEach((btn, i) => {
    //             if (btn.getAttribute('href') === hash) {
    //                 contents.forEach((el) => {
    //                     el.classList.remove('active');
    //                 });

    //                 contents[i].classList.add('active');

    //                 links.forEach((link) => {
    //                     link.parentElement?.classList.remove('active');
    //                 });

    //                 btn.parentElement?.classList.add('active');
    //             }
    //         });
    //     });
    // }
}

const _module = { init };

export default _module;
