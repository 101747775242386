import delegate from 'delegate';

import { axios } from './axios';

const LOADING_CLASS = 'loading';
export const ACTIVE_CLASS = 'is-active';

export const API = {
    ADD_TO_FAVOURITES: `/local/ajax/catalog/favorites.php`,
    REMOVE_FROM_FAVOURITES: `/local/ajax/catalog/favorites.php`,
    GET_FAVOURITES: `/local/ajax/catalog/favorites.php`,
};

export function addToFavouritesById(id: string | number){
    return axios.post(API.ADD_TO_FAVOURITES, {
        id: id,
        action: 'favorite_add',
    });
}
export function removeFromFavouritesById(id: string | number){
    return axios.post(API.REMOVE_FROM_FAVOURITES, {
        id: id,
        action: 'favorite_delete',
    });
}

async function addToFavourites(element: HTMLElement) {
    element.classList.add(LOADING_CLASS);
    try {
        const { data } = await addToFavouritesById(element.dataset.catalogId);
        if (data.success) {
            element.classList.add(ACTIVE_CLASS);
            ym(45733218,'reachGoal','add_wishlist');
        }
    } finally {
        element.classList.remove(LOADING_CLASS);
    }
}

async function removeFromFavourites(element: HTMLElement) {
    element.classList.add(LOADING_CLASS);
    try {
        const { data } = await removeFromFavouritesById(element.dataset.catalogId);
        if (data.success) {
            element.classList.remove(ACTIVE_CLASS);
        }
    } finally {
        element.classList.remove(LOADING_CLASS);
    }
}

function init() {
    const favouritesContainer = document.querySelector('.js-favourites');

    delegate(document, '.js-add-to-favourites', 'click', (event: any) => {
        event.preventDefault();
        const isFavourite = event.delegateTarget.classList.contains(ACTIVE_CLASS);
        if (isFavourite) {
            removeFromFavourites(event.delegateTarget);
        } else {
            addToFavourites(event.delegateTarget);
        }
    });

    delegate(document, '.js-remove-from-favourites-list', 'click', async (event: any) => {
        const target = event.delegateTarget;
        await removeFromFavourites(target);
        const card = target.closest('.favourites-list__item');
        card?.remove();

        if (favouritesContainer && Array.from(document.querySelectorAll('.js-favourites-list-item')).length === 0) {
            favouritesContainer.textContent = 'Список избранного пуст';
        }
    });
}

const _module = { init };

export default _module;
