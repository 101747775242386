import delegate from 'delegate';
import serialize from 'form-serialize';
import axios from 'axios';
import debounce from 'lodash.debounce';

let filter: HTMLFormElement | null;
let container: HTMLElement | null;
let loadMoreBtn: HTMLAnchorElement | null;

const { CancelToken } = axios;
let cancelRequest: any;

function setLoadingState() {
    container?.classList.add('is-loading');
    loadMoreBtn?.setAttribute('disabled', 'disabled');
}

function removeLoadingState() {
    container?.classList.remove('is-loading');
    loadMoreBtn?.removeAttribute('disabled');
}

async function fetchData() {
    cancelRequest?.();

    if (filter?.action) {
        const data = serialize(filter);
        const url = `${window.location.pathname}?${data}`;

        setLoadingState();

        try {
            const response = await axios.get(url, {
                params: { AJAX_REQUEST: 'Y' },
                cancelToken: new CancelToken((c) => {
                    cancelRequest = c;
                }),
            });

            if (response.data.success) {
                if (container) {
                    container.innerHTML = response.data.data.html || '';
                }

                if (loadMoreBtn) {
                    if (response.data.data.loadMoreUrl) {
                        loadMoreBtn.removeAttribute('hidden');
                        loadMoreBtn.setAttribute('href', response.data.data.loadMoreUrl);
                    } else {
                        loadMoreBtn.setAttribute('hidden', 'hidden');
                    }
                }

                window.history.pushState(null, '', url);
            } else {
                throw new Error(response.data.message);
            }
        } finally {
            removeLoadingState();
        }
    }
}

const debouncedFetchData = debounce(fetchData, 600);

function onSubmit(event: any) {
    event.preventDefault();
    fetchData();
}

function init() {
    filter = document.querySelector<HTMLFormElement>('form.js-blog-filter');
    container = document.querySelector<HTMLElement>('.js-blog-content');
    loadMoreBtn = document.querySelector<HTMLAnchorElement>('a.js-ajax-load-more-btn');

    if (filter) {
        filter.addEventListener('submit', onSubmit);
        delegate(filter, 'input, select', 'change', debouncedFetchData);
    }
}

const _module = { init };

export default _module;
