<template>
    <ul class="list-unstyled rating">
        <li v-for="i in max" class="rating-item" :key="i" :class="{ active: i <= rating}">
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.984 11.725a.5.5 0 00-.468 0l-2.852 1.511a1 1 0 01-1.444-1.1l.72-3.243a.5.5 0 00-.134-.462L.696 6.32a1 1 0 01.583-1.7l2.705-.338a.5.5 0 00.386-.272l1.486-2.972a1 1 0 011.788 0L9.13 4.01a.5.5 0 00.386.272l2.705.338a1 1 0 01.583 1.7l-2.11 2.11a.5.5 0 00-.135.462l.721 3.242a1 1 0 01-1.444 1.101l-2.852-1.51z"
                    fill="#F7D154"
                />
            </svg>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0,
        }
    },

    data() {
        return {
            max: 5,
        }
    },

    computed: {
        rating() {
            return Math.round(this.value);
        }
    }
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';

app-rating {
    display: inline-block;
}

.rating {
    display: flex;
}

.rating-item {
    &:not(:last-child) {
        margin-right: 2px;
    }

    &:not(.active) {
        path {
            fill: $color-black-50;
        }
    }
}
</style>
