import intlTelInput from 'intl-tel-input';

import { initPhoneMask, destroyPhoneMask } from './masks';

const map = new WeakMap<HTMLInputElement, intlTelInput.Plugin>();

function init(container: Element | Document = document) {
    const inputs = Array.from(
        container.querySelectorAll('input.js-intl-phone-code:not(.intl--initialized)'),
    ) as HTMLInputElement[];
    inputs.forEach((input) => {
        const iti = intlTelInput(input, {
            onlyCountries: ['ru', 'by', 'kz'],
            initialCountry: 'ru',
            localizedCountries: { ru: 'Россия', by: 'Беларусь', kz: 'Казахстан' },
        });
        map.set(input, iti);
        input.classList.add('intl--initialized');

        input.addEventListener('countrychange', () => {
            const countryData = iti.getSelectedCountryData();
            destroyPhoneMask(input);
            initPhoneMask(input, countryData.dialCode);
        });
    });
}

function destroy(container: Element | Document = document) {
    const inputs = Array.from(container.querySelectorAll('input.js-intl-phone-code')) as HTMLInputElement[];
    inputs.forEach((input) => {
        const iti = map.get(input);
        if (iti) {
            iti.destroy();
            map.delete(input);
        }
        input.classList.remove('intl--initialized');
    });
}

const _module = { init, destroy };

export default _module;
