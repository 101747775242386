import delegate from 'delegate';
import popups from './popups';

function init() {
    let timer: NodeJS.Timeout;

    delegate(document, 'a.js-video-popup', 'click', (event: any) => {
        clearTimeout(timer);
        const target = event.delegateTarget as HTMLAnchorElement;
        const iframe = document.querySelector<HTMLIFrameElement>('iframe.js-video-popup__iframe');
        const popup = popups.getInstanceByElement(document.querySelector('[data-lit-popup="video"]'));

        if (iframe && target.dataset.embedUrl) {
            iframe.src = target.dataset.embedUrl;
        }

        popup?.open();

        popup?.on('close-complete', () => {
            if (iframe) {
                timer = setTimeout(() => {
                    iframe.src = '';
                }, 300);
            }
        });
    });
}

const _module = { init };

export default _module;
