import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import * as Cookies from 'js-cookie';

import Notifications from './components/vue/Notifications/Notifications.vue';
import Scroller from './components/vue/Scroller/Scroller.vue';
import Loader from './components/vue/Loader/Loader.vue';
import Rating from './components/vue/Rating/Rating.vue';
import FuzzySearch from './components/vue/FuzzySearch/FuzzySearch.vue';
import ScrolltopButton from './components/vue/ScrolltopButton/ScrolltopButton.vue';
import CartAddNotifications from './components/vue/Cart/CartAddNotifications.vue';
import { Collapse } from './custom-elements/Collapse/Collapse';
import { AddReviewButtonAsync } from './custom-elements/AddReviewButtonAsync/AddReviewButtonAsync';

Vue.use(vueCustomElement);

export function registerCustomElements() {
    window.customElements.define('app-collapse', Collapse);
    window.customElements.define('app-add-review-button-async', AddReviewButtonAsync);
    Vue.customElement('app-notifications', Notifications);
    Vue.customElement('app-scroller', Scroller);
    Vue.customElement('app-loader', Loader);
    Vue.customElement('app-rating', Rating);
    Vue.customElement('fuzzy-search', FuzzySearch);
    Vue.customElement('scrolltop-button', ScrolltopButton);
    Vue.customElement('cart-notifications', CartAddNotifications);

    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        Vue.customElement('cookies-agreement', () =>
            import(
                /* webpackChunkName: "CookiesAgreement" */ './components/vue/CookiesAgreement/CookiesAgreement.vue'
            ).then((m) => m.default),
        );
    }

    if (!Cookies.get('LOCATION_ID')) {
        Vue.customElement('location-identifier', () =>
            import(
                /* webpackChunkName: "LocationIdentifier" */ './components/vue/LocationIdentifier/LocationIdentifier.vue'
            ).then((m) => m.default),
        );
    }

    Vue.customElement('catalog-detail-action', () =>
        import(
            /* webpackChunkName: "CatalogDetailAction" */ './components/vue/CatalogDetailAction/CatalogDetailAction.vue'
            ).then((m) => m.default),
    );

    Vue.customElement('autocomplete-input', () =>
        import(
            /* webpackChunkName: "AutocompleteInput" */ './components/vue/AutocompleteInput/AutocompleteInput.vue'
        ).then((m) => m.default),
    );

    Vue.customElement('app-sharing', () =>
        import(/* webpackChunkName: "Sharing" */ './components/vue/Sharing/Sharing.vue').then((m) => m.default),
    );

    Vue.customElement('app-cart', () =>
        import(/* webpackChunkName: "Cart" */ './components/vue/Cart/Cart.vue').then((m) => m.default),
    );

    Vue.customElement('app-order', () =>
        import(/* webpackChunkName: "Order" */ './components/vue/Order/Order.vue').then((m) => m.default),
    );

    Vue.customElement('authorize-base', () =>
        import(/* webpackChunkName: "AuthorizeBase" */ './components/vue/authorization/AuthorizeBase.vue').then(
            (m) => m.default,
        ),
    );

    Vue.customElement('change-phone-base', () =>
        import(/* webpackChunkName: "AuthorizeBase" */ './components/vue/ChangePhone/ChangePhoneBase.vue').then(
            (m) => m.default,
        ),
    );

    Vue.customElement('one-click-order', () =>
        import(/* webpackChunkName: "AuthorizeBase" */ './components/vue/OneClickOrder/OneClickOrderBase.vue').then(
            (m) => m.default,
        ),
    );

    Vue.customElement('app-coupons', () =>
        import(
            /* webpackChunkName: "CatalogDetailAction" */ './components/vue/Coupons/Coupon.vue'
        ).then((m) => m.default),
    );

    Vue.customElement('catalog-item-cart-action', () =>
        import(
            /* webpackChunkName: "CatalogItemCartAction" */ './components/vue/CatalogItemCartAction/CatalogItemCartAction.vue'
        ).then((m) => m.default),
    );
}
