import { fetchGlide } from '../dynamic-modules';
import { withLazyLoading } from '../components/glide-enhance';
import Pagination from '../components/slider/components/Pagination';

document.addEventListener('DOMContentLoaded', () => {
    const container = document.querySelector('.js-advantages-slider');

    if (container) {
        const slides = Array.from(document.querySelectorAll('.glide__slide'));
        const totalSlides = slides.length;
        let slider: any;
        let isInitialized = false;

        const attemptToInit = async () => {
            if (window.matchMedia('(max-width: 767px)').matches) {
                if (totalSlides > 1 && !isInitialized) {
                    isInitialized = true;
                    const { default: Glide } = await fetchGlide();
                    slider = withLazyLoading(Glide, container, {
                        type: 'carousel',
                        perView: 1,
                        animationDuration: 600,
                    });

                    slider.mount({ Pagination });
                }
            } else if (isInitialized) {
                slider.destroy();
                slider = null;
                isInitialized = false;
            }
        };

        attemptToInit();
        window.addEventListener('resize', attemptToInit);
    }
});
