import Axios from 'axios';
import {API as CartAPI, getCartAmount, setCartAmount} from './cart.ts'
import {dispatcher} from "./dispatcher";
import {ACTIVE_CLASS, addToFavouritesById, API, removeFromFavouritesById} from "./favourites";
import {axios} from "./axios";


window.mindboxQuery =  {
    whishlistState: [],

    addToCartByXmlId: function (id, amount) {
        return new Promise(async (resolve) => {
            const {data} = await Axios.post(CartAPI.ADD_TO_CART, {id, amount, action: 'addToBasketByXmlId'});
            if (data.success)
            {
                this.cartState = data.cart.basketData
                setCartAmount(data.basket_count);
                dispatcher.dispatch('add-to-cart-complete', {
                    id,
                    title: data.data.title,
                    img: data.data.img,
                    price: data.data.price,
                    priceOld: data.data.price_old,
                });
            }
            return resolve(true)
        })
    },

    addToWishListByXmlId: function (xmlId) {
        return new Promise(async (resolve) => {
            const { data } = await axios.post(API.ADD_TO_FAVOURITES, {
                xml_id: xmlId,
                action: 'favorite_add',
            });
            if( data.success )
            {
                this.whishlistState = Array.from(new Set(this.whishlistState.concat(data.id)))
                document.querySelectorAll('.js-add-to-favourites[data-catalog-id="' + data.id + '"]').forEach(item => {
                    item.classList.add(ACTIVE_CLASS)
                })
            }
            return resolve(true)
        })
    },

    removeFromWishListByXmlId: function (xmlId) {
        return new Promise(async (resolve) => {
            const { data } = await axios.post(API.REMOVE_FROM_FAVOURITES, {
                xml_id: xmlId,
                action: 'favorite_delete',
            });
            if( data.success )
            {
                this.whishlistState = this.whishlistState.filter(item => item !== data.id)
                document.querySelectorAll('.js-add-to-favourites[data-catalog-id="' + data.id + '"]').forEach(item => {
                    item.classList.remove(ACTIVE_CLASS)
                })
            }
            return resolve(true)
        })
    }
}
