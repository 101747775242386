import delegate from 'delegate';
import Axios from 'axios';

import popups from './popups';
import { triggerCustomEvent, triggerEvent } from '@chipsadesign/frontend-utils';

type GetDeliveryResponse = BaseResponse & {
    data: {
        html: string;
    };
};

const API = {
    GET_DELIVERY: '/local/ajax/delivery/delivery.php'
};

document.addEventListener('DOMContentLoaded', () => {
    const previewButtons = Array.from(document.querySelectorAll('.js-catalog-preview-button'));
    const previewImages = Array.from(document.querySelectorAll('.js-catalog-preview-img'));
    const catalogAvalPopup = Array.from(document.querySelectorAll('.js-catalog-aval'));
    //const catalogDelivery = document.querySelector('#custom-delivery-element');

    previewButtons.forEach((btn, i) => {
        btn.addEventListener('click', (event: any) => {
            event.preventDefault();
            previewButtons.forEach((el) => {
                el.classList.remove('active');
            });

            btn.classList.add('active');

            previewImages.forEach((img) => {
                img.classList.remove('active');
            });
            previewImages[i].classList.add('active');
        });
    });

    catalogAvalPopup.forEach((btn) => {
        btn.addEventListener('click', (event) => {
            let productId = event.target.dataset.productid;
            document.getElementById('product-id-aval').value = productId;
        });
    });

    /*if (typeof(catalogDelivery) != 'undefined' && catalogDelivery != null) {
        const productId = catalogDelivery.dataset.productid;
        Axios.get<GetDeliveryResponse>(API.GET_DELIVERY, {params: {action: 'getDeliveryElement', product: productId }})
            .then(({ data }) => {
                if (data.success) {
                    catalogDelivery.innerHTML = data.html;
                }
            })
    }*/
    const smoothHeight = (itemSelector, buttonSelector, contentSelector, widthflag) => {
        const items = document.querySelectorAll(itemSelector)
        if (!items.length) return
        items.forEach(el => {
            const button = el.querySelector(buttonSelector)
            const content = el.querySelector(contentSelector)

            if (button) {
                button.addEventListener('click', () => {
                    if (el.dataset.open !== 'true') {
                        el.dataset.open = 'true'
                        if (widthflag === "true"){
                            let height_container = document.querySelectorAll('.catalog-detail-stat');
                            let height_total = 0;
                            height_container.forEach(function (item) {
                                height_total = height_total + item.clientHeight;
                            })
                            content.style.maxHeight = `${height_total}px`
                        } else {
                            content.style.maxHeight = `${content.scrollHeight}px`
                        }

                    } else {
                        el.dataset.open = 'false'
                        content.style.maxHeight = ''
                    }
                })
            }
            const onResize = () => {
                if (el.dataset.open === 'true') {
                    if (parseInt(content.style.maxHeight) !== content.scrollHeight) {
                        content.style.maxHeight = `${content.scrollHeight}px`
                    }
                }
            }
            window.addEventListener('resize', onResize)
        })
    }
    if (document.querySelector('.catalog-tab__content') !== null) {
        var el = document.querySelector('.catalog-tab__content');
        var style = window.getComputedStyle(el, null).getPropertyValue('font-size');
        var fontSize = parseFloat(style);
        if (document.querySelector('.catalog-tab__content').clientHeight >= fontSize * 25.5) {
            document.querySelector('.item__button').style.display = 'block';
        }
    }
    if (document.querySelector('.catalog-detail-stats-list') !== null) {
        if (document.querySelector('.catalog-detail-stats-list').clientHeight >= 250) {
            document.querySelector('.item__button_stats').style.display = 'block';
        }
    }
    smoothHeight('.section__item', '.item__button', '.item__content', 'false')
    smoothHeight('.stats-block', '.item__button_stats', '.catalog-detail-stats-list','true')
});
