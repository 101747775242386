import delegate from 'delegate';

import { axios } from './axios';

const API = {
    POST_GEOLOCATION_CITY_ID: `/local/ajax/geoTarget.php`,
};

function init() {
    delegate(document, '[data-geolocation-type="header"]', 'click', async (event: any) => {
        event.preventDefault();
        const { id } = event.delegateTarget.dataset;

        const result = await axios.post(API.POST_GEOLOCATION_CITY_ID, {
            id,
            action: 'setCity',
        });
        if (result.data.success) {
            window.location.reload();
        } else {
            throw new Error(result.data.message);
        }
    });
}

const _module = { init };

export default _module;
