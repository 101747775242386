import './webpack-imports';
import './sentry';
import './polyfills';
import axios from 'axios';
import { registerCustomElements } from './custom-elements';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
import floatedLabels from './inits/floated-labels';
import ajaxForms from './inits/ajax-forms';
import dropdowns from './inits/dropdowns';
import cart from './inits/cart';
import favourites from './inits/favourites';
import masks from './inits/masks';
import tabs from './inits/tabs';
import popups from './inits/popups';
import closeOnSuccessForm from './inits/close-on-success-form';
import shuffle from './inits/shuffle';
import copyToClipboard from './inits/copy-to-clipboard';
import invitationSharing from './inits/invitation-sharing';
import productCards from './inits/product-cards';
import auth from './inits/auth';
import order from './inits/order';
import geolocation from './inits/geolocation';
import menu from './inits/menu';
import { lazyload } from './inits/lazy-load';
import intlPhoneCode from './inits/intl-phone-code';
import coupons from './inits/coupons';
import popupForms from './inits/popup-forms';
import videoPopup from './inits/video-popup';
import imagePopup from './inits/image-popup';
import blogFilter from './inits/blog-filter';
import catalogMenu from './inits/catalog-menu';
import checkbox from './inits/checkbox';
import './inits/action-slider';
import './inits/catalog-action-slider';
import './inits/catalog-popup';
import './inits/catalog';
import './inits/search';
import './inits/catalog-detail';
import './inits/detail-slider';
import './inits/advantages-slider';
import './inits/select';
import './inits/back-link';
import './inits/inline-video';
import './inits/delivery-city';
import './inits/reviews';
import './inits/ajax-pagination';
import './inits/any-query';
import './inits/mindbox-query';
import './metrics';
import './inits/greedy-nav';

(window as any).getRRFavorites = () => {
    return axios
        .post('/local/ajax/catalog/favorites.php?AJAX_REQUEST=Y', { action: 'favorite_get', AJAX_REQUEST: 'Y' })
        .then((data) => data.data);
};

(window as any).updateGoodInBasket = (id: string, amount: number) =>
    axios.post('/local/ajax/sale/basket.php', { amount, id, action: 'updateBasket' });

document.addEventListener('DOMContentLoaded', () => {
    registerCustomElements();
    lazyload.update();
    calculateScrollbarWidth();
    intlPhoneCode.init();
    floatedLabels.init();
    ajaxForms.init();
    dropdowns.init();
    cart.init();
    favourites.init();
    masks.init();
    tabs.init();
    popups.init();
    popupForms.init();
    closeOnSuccessForm.init();
    shuffle.init();
    copyToClipboard.init();
    invitationSharing.init();
    productCards.init();
    auth.init();
    order.init();
    geolocation.init();
    menu.init();
    coupons.init();
    videoPopup.init();
    imagePopup.init();
    blogFilter.init();
    catalogMenu.init();
    checkbox.init();

    const catalogInit = setInterval(function () {
        if (
            document.querySelector('.catalog-open-btn') &&
            document.querySelectorAll('.js-catalog-block-list-link').length !== 0
        ) {
            catalogMenu.init();
            clearInterval(catalogInit);
        }
        if (!document.querySelector('.catalog-open-btn')) {
            clearInterval(catalogInit);
        }
    }, 500);
});
