<template>
    <div>
        <input
            type="text"
            class="form-control fuzzy-search__input"
            autocomplete="off"
            role="textbox"
            aria-autocomplete="list"
            placeholder="Город"
            v-model="query"
        />
        <ul class="fuzzy-search-list dropdown-list" v-if="data.length > 0">
            <li class="dropdown-list__item" v-for="item in data" :key="item.title">
                <a v-bind="item.attrs">{{ item.title }}</a>
            </li>
        </ul>
        <div v-else class="dropdown__link">Совпадений не найдено</div>
    </div>
</template>

<script lang="ts">
import Fuse from 'fuse.js';

export default {
    data() {
        return {
            fuse: null,
            query: '',
            initialData: [],
            data: [],
        };
    },

    mounted() {
        this.initialData = this.$slots.default.map((obj) => ({
            title: obj.data.domProps.innerHTML.trim(),
            attrs: obj.data.attrs,
        }));

        this.data = this.initialData;

        this.fuse = new Fuse(this.data, {
            threshold: 0,
            location: 0,
            distance: 100,
            findAllMatches: true,
            keys: ['title'],
        });
    },

    watch: {
        query(value) {
            if (value) {
                if (this.fuse) {
                    const result = this.fuse.search(value);
                    this.data = result.map((item) => item.item);
                }
            } else {
                this.data = this.initialData;
            }
        },
    },
};
</script>

<style lang="scss">
fuzzy-search {
    display: block;
}

.fuzzy-search__input {
    margin-bottom: 22px;
    border-radius: 4px;
    padding: 12px 16px;
}
</style>
