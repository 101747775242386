import { dispatcher } from './dispatcher';

function init() {
    const authPopupElement = document.querySelector('[data-lit-popup="login"]');
    if (authPopupElement) {
        authPopupElement.addEventListener('open', ({ detail: { triggerElement } }: any) => {
            dispatcher.dispatch('authorize-base.reset');

            if (triggerElement) {
                dispatcher.dispatch('authorize-base.set-back-url', { url: triggerElement.href });
            }
        });
    }
}

const _module = { init };

export default _module;
