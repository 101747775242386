import { axios } from './axios';
import type { BaseResponse } from '../types';
import { isIE } from '../utils/is-ie';

type AjaxContentResponse = BaseResponse & {
    data: {
        html: string;
        loadMoreUrl?: string;
    };
};

document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll<HTMLElement>('.js-ajax-content-block')).forEach((container) => {
        const contentContainer = container.querySelector<HTMLElement>('.js-ajax-content');
        const loadMoreBtn = container.querySelector<HTMLAnchorElement>('a.js-ajax-load-more-btn');
        const filterButtons = Array.from(container.querySelectorAll<HTMLAnchorElement>('a.js-ajax-filter-btn'));

        function setLoadingState() {
            setTimeout(() => {
                if (loadMoreBtn) {
                    loadMoreBtn.setAttribute('disabled', 'disabled');
                }
            }, 0);
        }

        function removeLoadingState() {
            if (loadMoreBtn) {
                loadMoreBtn.removeAttribute('disabled');
            }
        }

        async function fetchData(this: HTMLAnchorElement, event: any) {
            event.preventDefault();
            event.stopPropagation();
            const url = this.href;

            setLoadingState();

            try {
                const response = await axios.get<AjaxContentResponse>(url);
                const { loadMoreUrl, html } = response.data.data;
                if (contentContainer) {
                    if (isIE) {
                        contentContainer.innerHTML = html;
                    } else {
                        const template = document.createElement('template');
                        template.innerHTML = html;
                        contentContainer.innerHTML = '';
                        contentContainer.appendChild(template.content);
                    }
                }

                if (loadMoreBtn) {
                    if (loadMoreUrl) {
                        loadMoreBtn.removeAttribute('hidden');
                        loadMoreBtn.setAttribute('href', loadMoreUrl);
                    } else {
                        loadMoreBtn.setAttribute('hidden', 'hidden');
                    }
                }
            } catch (err) {
                if (contentContainer) {
                    contentContainer.innerHTML = err.message;
                }
                throw err;
            } finally {
                removeLoadingState();
            }
        }

        async function onLoadMoreBtnClick(this: HTMLAnchorElement, event: any) {
            event.preventDefault();
            event.stopPropagation();
            const url = this.href;
            let prevBtnHtml = '';

            setLoadingState();

            if (loadMoreBtn) {
                prevBtnHtml = loadMoreBtn.innerHTML;
                loadMoreBtn.innerHTML = '<app-loader></app-loader>';
            }

            try {
                const response = await axios.get<AjaxContentResponse>(url);
                const { loadMoreUrl, html } = response.data.data;

                if (contentContainer) {
                    if (isIE) {
                        contentContainer.innerHTML += html || '';
                    } else {
                        const template = document.createElement('template');
                        template.innerHTML = html;
                        contentContainer.appendChild(template.content);
                    }
                }

                if (loadMoreBtn) {
                    if (loadMoreUrl) {
                        loadMoreBtn.removeAttribute('hidden');
                        loadMoreBtn.setAttribute('href', loadMoreUrl);
                    } else {
                        loadMoreBtn.setAttribute('hidden', 'hidden');
                    }
                }
            } finally {
                removeLoadingState();

                if (loadMoreBtn && prevBtnHtml) {
                    loadMoreBtn.innerHTML = prevBtnHtml;
                }
            }
        }

        if (loadMoreBtn) {
            loadMoreBtn.addEventListener('click', onLoadMoreBtnClick);
        }

        filterButtons.forEach((btn) => {
            btn.addEventListener('click', fetchData);
        });
    });
});
