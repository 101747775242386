export default (Glide: any, _: any, Events: any) => {
    const totalSlides = Array.from(Glide.selector.querySelectorAll('.glide__slide:not(.glide__slide--clone)')).length;
    const navContainers = Array.from((Glide.selector as Element).querySelectorAll('.glide__pagination'));
    let buttons: HTMLElement[] = [];
    function onNavButtonClick(this: HTMLElement) {
        Glide.go(`=${this.dataset.slide}`);
    }

    function generateNavigation() {
        navContainers.forEach((navContainer) => {
            let navItemsString = '';

            for (let i = 0; i < totalSlides; i++) {
                navItemsString += `
                    <li class="glide__pagination-list__item">
                        <button class="glide__pagination-bullet" data-slide="${i}" aria-label="Перейти на слайд ${
                    i + 1
                }">
                            <span class="visually-hidden">${i + 1}</span>
                        </button>
                    </li>
                `;
            }

            const template = `
                <nav aria-label="Slider pagination">
                <ul class="glide__pagination-list">${navItemsString}</ul>
                </nav>
            `;

            navContainer.innerHTML = template;

            const activeBtn = buttons.find((btn) => btn.dataset.slide === `${Glide.index}`);

            if (activeBtn && activeBtn.parentElement) {
                activeBtn.parentElement.classList.add('active');
            }
        });
    }

    function initNavigation() {
        buttons = Array.from(Glide.selector.querySelectorAll('[data-slide]')) as HTMLElement[];
        buttons.forEach((btn) => {
            btn.addEventListener('click', onNavButtonClick);
        });

        Glide.on('run', () => {
            const nextActiveBtns = buttons.filter((btn) => btn.dataset.slide === `${Glide.index}`);

            buttons.forEach((btn) => {
                btn.parentElement!.classList.remove('active');
            });

            nextActiveBtns.forEach((btn) => {
                btn.parentElement!.classList.add('active');
            });
        });
    }

    function destroyNavigation() {
        buttons.forEach((btn) => {
            btn.removeEventListener('click', onNavButtonClick);
        });
        buttons = [];

        navContainers.forEach((navContainer) => {
            navContainer.innerHTML = '';
        });
    }

    function setActiveByIndex(index: number) {
        navContainers.forEach((navContainer) => {
            const elements = Array.from(navContainer.querySelectorAll('.glide__pagination-list__item'));
            if (elements[index]) {
                elements.forEach((btn) => btn.classList.remove('active'));
                elements[index].classList.add('active');
            }
        });
    }

    Events.on('destroy', destroyNavigation);

    return {
        mount() {
            generateNavigation();
            initNavigation();
            setActiveByIndex(0);
        },
        setActiveByIndex,
    };
};
