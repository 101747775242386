export default (Glide: any, Components: any, Events: any) => {
    const arrowContainers = Array.from(Glide.selector.querySelectorAll('.js-slider__arrows')) as HTMLElement[];
    let isInitialized = false;

    function createArrows(container: HTMLElement) {
        const arrow = `
            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.015 12.71a1.057 1.057 0 001.456 0 .967.967 0 000-1.404L3.515 7.493l11.456-.001c.568 0 1.029-.445 1.029-.993s-.46-.992-1.03-.992l-11.455.001 3.957-3.814a.967.967 0 000-1.403A1.047 1.047 0 006.744 0c-.264 0-.527.097-.728.29L.302 5.799A.974.974 0 000 6.5c0 .263.109.516.302.702l5.713 5.508z" fill="#53A17E"/>
            </svg>
        `;
        container.innerHTML = `
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--prev" data-glide-dir="<" aria-label="Предыдущий слайд">${arrow}</button>
                <button class="glide__arrow glide__arrow--next" data-glide-dir=">" aria-label="Следующий слайд">${arrow}</button>
            </div>
        `;
    }

    function init() {
        if (isInitialized) return;

        arrowContainers.forEach((container) => {
            createArrows(container);
        });

        isInitialized = true;
    }

    function destroy() {
        arrowContainers.forEach((container) => {
            container.innerHTML = '';
        });

        isInitialized = false;
    }

    let activeSlide: HTMLElement | null;

    Events.on('build.before', init);
    Events.on('run', () => {
        activeSlide = Glide.selector.querySelector('.glide__slide--active') as HTMLElement | null;
        activeSlide?.classList.add('glide__slide--leaving');
    });
    Events.on('run.after', () => {
        if (activeSlide) {
            activeSlide.classList.remove('glide__slide--leaving');
            activeSlide = null;
        }
    });
    Glide.selector.addEventListener('destroy', destroy, { once: true });

    return {};
};
