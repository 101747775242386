import { fetchSlimSelect } from '../dynamic-modules';
import popups from './popups';
import Axios from "axios";

async function init() {

        const elements = Array.from<HTMLSelectElement>(
            document.querySelectorAll('select.slim-select'),
        ).filter(el => !el.parentElement?.classList.contains('slim-select-container--initialized'));

        if (elements.length > 0) {
            const { default: SlimSelect } = await fetchSlimSelect();

            elements.forEach((el) => {
                const parent = el.parentElement;

                let instance;

                if (el.classList.contains('js-custom-data-select')) {
                    const options = Array.from(el.querySelectorAll('option'));

                    if (!options.length) {
                        parent?.remove();
                    }

                    const data = options.map(op => ({
                        innerHTML: op.dataset.html,
                        text: op.textContent || '',
                        value: op.value,
                        data: op.dataset,
                        id: op.dataset.id,
                        selected: op.selected
                    }))

                    instance = new SlimSelect({
                        select: el,
                        showSearch: false,
                        placeholder: el.dataset.placeholder || '',
                        data: data,
                        allowDeselect: false,
                        allowDeselectOption: true,
                        onChange: () => {
                            addListeners(el);
                        }
                    });

                    addListeners(el);
                    el.addEventListener('removeOption', (event) => {
                        const optionId = event.detail.optionId;
                        const { removeData } = Axios.get('/local/ajax/sale/delete_address.php', {
                            params: {
                                action: 'deleteAddress',
                                optionId: optionId
                            },
                        });
                        addListeners(el);
                    })
                } else {
                    instance = new SlimSelect({
                        select: el,
                        showSearch: false,
                        placeholder: el.dataset.placeholder || '',
                    });
                }

                if (parent?.classList.contains('slim-select-container')) {
                    parent.classList.add('slim-select-container--initialized');
                }
            });
        }

}

function addListeners(el: HTMLElement) {
    const parent = el.parentElement;

    setTimeout(() => {
        parent && parent.querySelectorAll('.ss-option .remove').forEach(removeBtn => {
            removeBtn.addEventListener('click', (e) => {
                const target = e.target;

                if (!target) return;

                e.stopPropagation();

                const targetElement = target.closest('.ss-option');
                const option = document.querySelector(`option[data-id="${targetElement?.dataset.id}"]`);
                const optionId = option?.dataset.addressId;
                // todo: Возможно неправильно определяется optionId у последнего в селекте опшиона

                const popupElement = document.querySelector(`[data-lit-popup="delete-delivery-address"]`);
                const instance = popups.getInstanceByElement(popupElement);

                if (!instance || !popupElement) return;

                instance.on('open', instanceOpen);
                instance.on('close', instanceClose);

                instance.open();

                function instanceOpen() {
                    popupElement?.addEventListener('click', onClick);
                }
                function instanceClose() {
                    popupElement?.removeEventListener('click', onClick);
                }

                function onClick(e) {
                    if (e.target?.classList.contains('js-delete-delivery-address-delete')) {
                        instance?.close();

                        instance?.off('open', instanceOpen);
                        instance?.off('close', instanceClose);

                        option?.remove();

                        const removeOptionEvent = new CustomEvent('removeOption', {detail: {optionId: optionId}});
                        el.dispatchEvent(removeOptionEvent);
                    }
                }
            })
        })
    }, 200)
}

const _module = { init };

export default _module;

document.addEventListener('DOMContentLoaded', () => {
    init();
});
