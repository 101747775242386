export const fetchGlide = () =>
    Promise.all([
        import(/* webpackChunkName: "glide" */ '@glidejs/glide'),
        import(/* webpackChunkName: "glide-css" */ '../css/components/_base-slider.scss'),
    ]).then(([slider]) => slider);

export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchImask = () =>
    Promise.all([
        import(
            'imask/esm/imask'
            /* webpackChunkName: "imask" */
        ),
        import(
            'imask/esm/masked/number'
            /* webpackChunkName: "imask-number" */
        ),
    ]).then(([m]) => m);

export const fetchSlimSelect = () =>
    import(
        'slim-select'
        /* webpackChunkName: "slim-select" */
    );

export const fetchShuffle = () =>
    import(
        'shufflejs'
        /* webpackChunkName: "shufflejs" */
    );
