import delegate from 'delegate';
import Axios from 'axios';
import popups from './popups';
import type { BaseResponse } from '../types';
import { dispatcher } from './dispatcher';

type AddToCartResponse = BaseResponse & {
    data: {
        title: string;
        img: string;
        price: string;
        // eslint-disable-next-line camelcase
        price_old?: string;
    };
};

type GetCartAmountResponse = BaseResponse & {
    data: {
        amount: number;
    };
};

export const API = {
    ADD_TO_CART: '/local/ajax/sale/basket.php',
    GET_CART_AMOUNT: `/local/ajax/sale/basket_small.php`,
};

export function getCartAmount() {
    return Axios.get<GetCartAmountResponse>(API.GET_CART_AMOUNT);
}

const cartAmountElements = Array.from(document.querySelectorAll('.js-cart-amount'));
// Fetch early
const getCartAmountPromise = getCartAmount();

export function setCartAmount(amount: number) {
    const amountText = amount > 99 ? '99+' : `${amount}`;
    cartAmountElements.forEach((el) => {
        if (amount > 0) {
            el.classList.add('is-visible');
        } else {
            el.classList.remove('is-visible');
        }

        el.textContent = amountText;
    });
}

function init() {
    getCartAmountPromise.then(({ data }) => {
        setCartAmount(data.data.amount);
    });

    delegate(document, '.js-add-to-cart', 'click', async (event: any) => {
        const target = event.delegateTarget;
        const id = target.dataset.catalogId;
        const prevHtml = target.innerHTML;
        const warning_message_limit = document.querySelector<HTMLElement>('[data-lit-popup="warning_message"]');
        let warningPopup = popups.getInstanceByElement(warning_message_limit);
        target.setAttribute('disabled', 'disabled');
        target.innerHTML = '<app-loader class="add-to-cart-loader"></app-loader>';

        try {
            const { data } = await Axios.post<AddToCartResponse>(API.ADD_TO_CART, { id, action: 'addToBasket' });
            if(data.success===false)alert(data.message);
            if(data.success==='weight_limit'){
                warningPopup.open();
            }
            else{
                if (data.success) {
                    getCartAmount().then((response) => {
                        setCartAmount(response.data.data.amount);
                    });
                    setTimeout(() => {
                        dispatcher.dispatch('add-to-cart-complete', {
                            id,
                            title: data.data.title,
                            img: data.data.img,
                            price: data.data.price,
                            priceOld: data.data.price_old,
                        });

                        //e-commerce event
                        gtag('event', 'add', {
                            currency: "RUB",
                            value: data.data.price,
                            items:{
                                item_id:data.cart.ids,
                            }
                        });

                        //Mail.ru targeting
                        _tmr.push({
                            type: 'itemView',
                            productid: data.cart.ids,
                            pagetype: 'cart',
                            totalvalue: data.cart.totalPrice,
                            list: '1'
                        });

                        _tmr.push({
                            id: "3264093",
                            type: "reachGoal",
                            goal: "cart",
                            value: Math.ceil(data.data.priceRaw)
                        });

                        //Yandex metrics
                        ym?.(45733218, 'reachGoal', 'dobavlenie_v_korziny');
                    }, 0);
                    dataLayer.push({
                        "ecommerce": {
                            "currencyCode": "RUB",
                            "add": {
                                "products": [
                                    {
                                        id,
                                        "name": data.data.title,
                                        "price": data.data.priceRaw,
                                        "category": data.data.category,
                                    }
                                ]
                            }
                        }
                    });
                }
            }
        } finally {
            target.removeAttribute('disabled');
            target.innerHTML = prevHtml;
        }
    });
}

const _module = { init };

export default _module;
