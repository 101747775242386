import { dispatcher } from './dispatcher';

async function copyToClipboard(text: string) {
    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
    } else {
        const input = document.createElement('input');
        input.style.display = 'none';
        input.value = text;
        document.body.appendChild(input);
        input.focus();
        input.select();
        const isSuccessfulCopy = document.execCommand('copy');
        if (!isSuccessfulCopy) {
            throw new Error('Failed to copy text.');
        }
    }
}
function init() {
    const buttons = Array.from(document.querySelectorAll('.js-copy-to-clipboard'));

    buttons.forEach((btn) => {
        let isProcessing = false;

        btn.addEventListener('click', async () => {
            if (isProcessing) return;
            isProcessing = true;
            const text = btn.textContent!.trim();
            try {
                await copyToClipboard(text);
                //dispatcher.dispatch('notification', { message: 'Скопировано в буфер обмена!' });
                btn.classList.add('js-copied-status');
                clearTimeout(window.copiedTimeout);
                window.copiedTimeout = setTimeout(function(){
                    btn.classList.remove('js-copied-status');
                },1500);
            } finally {
                isProcessing = false;
            }
        });
    });
}

const _module = { init };

export default _module;
