import delegate from 'delegate';

function init() {
    delegate(document, 'input', 'change', (event: any) => {
        const target = event.delegateTarget as HTMLInputElement;
        const checkboxLabel = event.delegateTarget.closest('.checkbox') as HTMLElement | null;

        if (checkboxLabel) {
            if (target.disabled) {
                checkboxLabel.classList.add('checkbox--disabled');
            } else {
                checkboxLabel.classList.remove('checkbox--disabled');
            }
        }
    });
}

const _module = { init };

export default _module;
