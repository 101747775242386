import { fetchGlide } from '../dynamic-modules';
import { withLazyLoading } from '../components/glide-enhance';
import Navigation from '../components/slider/components/Navigation';

const easeInOutQuart = 'cubic-bezier(0.76, 0, 0.24, 1)';

document.addEventListener('DOMContentLoaded', async () => {
    const sliders = [document.querySelector('.js-action-slider')];
    window.initSaleSlider(sliders);
});

window.initSaleSlider = function(sliders){
    if(sliders) {
        sliders.forEach(async (el) => {

            if (el && !el.classList.contains('sliderInitialized') ) {
                const slides = Array.from(el.querySelectorAll('.glide__slide'));
                const totalSlides = slides.length;

                if (totalSlides > 2) {
                    const {default: Glide} = await fetchGlide();
                    const instance = withLazyLoading(Glide, el, {
                        type: 'carousel',
                        perView: 3,
                        autoplay: 5000,
                        peek: {before: 32, after: 32},
                        gap: 20,
                        animationDuration: 700,
                        animationTimingFunc: easeInOutQuart,

                        breakpoints: {
                            1440: {
                                perView: 2,
                            },
                            1280: {
                                peek: {before: 25, after: 25},
                            },
                            812: {
                                perView: 2,
                            },
                            578: {
                                perView: 1,
                            },
                        },
                    });
                    el.classList.add('sliderInitialized');
                    instance.mount({Navigation});
                }
            }
        });
    }
}
