import { fetchGlide } from '../dynamic-modules';
import { withLazyLoading } from '../components/glide-enhance';
import Navigation from '../components/slider/components/Navigation';
import Pagination from '../components/slider/components/Pagination';

const easeInOutQuart = 'linear';

document.addEventListener('DOMContentLoaded', async () => {
    const el = document.querySelector('.js-catalog-action-slider'),
        banners_el = document.querySelector('.js-catalog-banners-action-slider'),
        banners_mobile = document.querySelector('.js-advantages-slider-mobile');

    if (el) {
        const slides = Array.from(el.querySelectorAll('.glide__slide'));
        const totalSlides = slides.length;

        if (totalSlides > 2) {
            const { default: Glide } = await fetchGlide();
            const instance = withLazyLoading(Glide, el, {
                type: 'carousel',
                perView: 6,
                peek: { before: 42, after: 32 },
                gap: 40,
                animationDuration: 400,
                animationTimingFunc: easeInOutQuart,

                breakpoints: {
                    1600: {
                        perView: 5,
                    },
                    1368: {
                        perView: 4,
                    },
                    1026: {
                        peek: { before: 25, after: 25 },
                        perView: 3,
                    },
                    768: {
                        perView: 2,
                        gap: 20,
                        peek: { before: 18, after: 16 },
                    },
                },
            });

            instance.mount({ Navigation });
            instance.mount({ Pagination });
        }
    }

    if (banners_el) {
        const slides = Array.from(banners_el.querySelectorAll('.glide__slide'));
        const totalSlides = slides.length;

        if (totalSlides > 2 && window.matchMedia('(min-width: 1025px)').matches) {
            const { default: Glide } = await fetchGlide();
            const instance = withLazyLoading(Glide, banners_el, {
                type: 'carousel',
                perView: 3,
                autoplay: 5000,
                peek: { before: 0, after: 32 },
                gap: 20,
                animationDuration: 400,
                animationTimingFunc: easeInOutQuart,

                breakpoints: {
                    1600: {
                        perView: 2,
                    },
                    1280: {
                        peek: { before: 25, after: 25 },
                    }
                },
            });

            instance.mount({ Navigation });
            instance.mount({ Pagination });
        }
    }
    if(banners_mobile){
        const slides = Array.from(document.querySelectorAll('.glide__slide'));
        const totalSlides = slides.length;
        let slider: any;
        let isInitialized = false;

        const attemptToInit = async () => {
            if (window.matchMedia('(max-width: 561px)').matches) {
                banners_mobile.classList.remove("js-action-slider");
                banners_mobile.classList.remove("action-slider");
                if (totalSlides > 1 && !isInitialized) {
                    isInitialized = true;
                    const { default: Glide } = await fetchGlide();
                    slider = withLazyLoading(Glide, banners_mobile, {
                        type: 'carousel',
                        perView: 1,
                        autoplay: 5000,
                        gap: 20,
                        animationDuration: 400,
                        animationTimingFunc: easeInOutQuart,
                        focusAt: 'center',
                        touchRatio: 1,
                        touchAngle:1000,

                    });

                    slider.mount({ Pagination });
                }
            } else if (isInitialized) {
                banners_mobile.classList.add("js-action-slider");
                banners_mobile.classList.add("action-slider");
                slider.destroy();
                slider = null;
                isInitialized = false;
            }
        };

        attemptToInit();
        window.addEventListener('resize', attemptToInit);
    }
});
