import Lazyload from 'vanilla-lazyload';

import { lazyload } from '../inits/lazy-load';

export const getPrevIndex = (currentIndex: number, total: number, step = 1) => (currentIndex - step + total) % total;

export const getNextIndex = (currentIndex: number, total: number, step = 1) => (currentIndex + step) % total;

export function withLazyLoading(_Constructor: any, el: Element, options: Record<string, any> = {}) {
    const instance = new _Constructor(el, options);

    const loadPrevNext = (slides: Element[], currentIndex: number) => {
        const prevSlideImg = slides[getPrevIndex(currentIndex, slides.length, options.perView - 1)]?.querySelector(
            'img',
        );
        const nextSlideImg = slides[getNextIndex(currentIndex, slides.length, options.perView - 1)]?.querySelector(
            'img',
        );

        [prevSlideImg, nextSlideImg].forEach((img) => {
            if (img && (!img.src || !img.classList.contains('loaded'))) {
                Lazyload.load(img);
            }
        });
    };

    instance.on('mount.after', () => {
        lazyload.update();
        const slides = Array.from(el.querySelectorAll('.glide__slide'));

        const observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    loadPrevNext(slides, instance.index);
                }
            });
        });

        observer.observe(el);

        instance.on('run.after', () => {
            loadPrevNext(slides, instance.index);
        });
    });

    return instance;
}
