import delegate from 'delegate';

const CONTAINER_SELECTOR = '.js-dropdown';
const TOGGLER_SELECTOR = '.js-dropdown-toggler';
const CHOSEN_TEXT_SELECTOR = '.js-dropdown-select__chosen-text';

const windowResizeListenersMap = new WeakMap();

export function close(dropdown: Element) {
    const togglers = Array.from(dropdown.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'false');
    });
    dropdown.classList.remove('opened');
}

function closeAllDropdowns() {
    const dropdowns = Array.from(document.querySelectorAll(CONTAINER_SELECTOR));
    dropdowns.forEach((dropdown) => close(dropdown));
}

export function open(dropdown: Element) {
    closeAllDropdowns();
    const togglers = Array.from(dropdown.querySelectorAll(TOGGLER_SELECTOR));

    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'true');
    });

    dropdown.classList.add('opened');
}

function toggle(event: any) {
    const dropdown = event.delegateTarget.closest(CONTAINER_SELECTOR);
    const isExpanded = event.delegateTarget.getAttribute('aria-expanded') === 'true';

    if (!dropdown) return;

    if (isExpanded) {
        close(dropdown);
    } else {
        open(dropdown);
    }
}

function closeOnOutsideClick(event: any) {
    if (!event.target.closest(CONTAINER_SELECTOR)) {
        closeAllDropdowns();
    }
}

export function changeLabel(dropdownElement: Element, label: string) {
    const chosenTextElement = dropdownElement.querySelector(CHOSEN_TEXT_SELECTOR);

    if (chosenTextElement) {
        chosenTextElement.textContent = label;
        close(dropdownElement);
    }
}

function onChange(event: any) {
    event.preventDefault();
    const dropdownElement = event.delegateTarget.closest('.dropdown');

    if (dropdownElement) {
        changeLabel(dropdownElement, event.delegateTarget.textContent);
    }
}

function initDropdown(dropdownElement: Element) {
    const togglers = Array.from(dropdownElement.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'false');
    });

    delegate(dropdownElement, TOGGLER_SELECTOR, 'click', toggle);
    delegate(dropdownElement, '.js-dropdown-link', 'click', onChange);
}

function destroyDropdown(dropdownElement: Element) {
    const togglers = Array.from(dropdownElement.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.removeEventListener('click', toggle);
    });
}

function init(container = document) {
    const dropdowns = Array.from(container.querySelectorAll(CONTAINER_SELECTOR)) as HTMLElement[];

    dropdowns.forEach((dropdown) => {
        if (dropdown.hasAttribute('data-init-on-media')) {
            const initOnResize = () => {
                const media = dropdown.dataset.initOnMedia;
                if (window.matchMedia(media!).matches) {
                    initDropdown(dropdown);
                } else {
                    destroyDropdown(dropdown);
                }
            };
            initOnResize();
            window.addEventListener('resize', initOnResize);
            windowResizeListenersMap.set(dropdown, initOnResize);
        } else {
            initDropdown(dropdown);
        }
    });

    document.addEventListener('click', closeOnOutsideClick);
}

function destroy(container = document) {
    const dropdowns = Array.from(container.querySelectorAll(CONTAINER_SELECTOR));

    dropdowns.forEach((dropdown) => {
        const listener = windowResizeListenersMap.get(dropdown);

        if (listener) {
            window.removeEventListener('resize', listener);
            windowResizeListenersMap.delete(dropdown);
        }

        destroyDropdown(dropdown);
    });

    document.removeEventListener('click', closeOnOutsideClick);
}

const _module = { init, destroy };

export default _module;
