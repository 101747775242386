import delegate from 'delegate';

function generateYoutubeEmbedURL(id: string) {
    const query = '?rel=0&showinfo=0&autoplay=1';
    return `https://www.youtube.com/embed/${id}${query}`;
}

function createIframe(id: string) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute('src', generateYoutubeEmbedURL(id));
    return iframe;
}

document.addEventListener('DOMContentLoaded', () => {
    delegate(document, '.js-inline-video', 'click', (event: any) => {
        event.preventDefault();
        const el = event.delegateTarget;
        const { videoId } = el.dataset;

        if (videoId) {
            const iframe = createIframe(videoId);
            el.addEventListener(
                'transitionend',
                () => {
                    if (el.parentElement) {
                        el.parentElement.removeChild(el);
                    }
                },
                { once: true },
            );

            if (el.parentElement) {
                el.parentElement.append(iframe);
                el.classList.add('inline-video__link--leave');
            }
        }
    });
});
