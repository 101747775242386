<template>
    <div class="app-notifications-list">
        <transition :key="notification.id" v-for="notification in notifications" name="fade">
            <div class="app-notification">
                <div class="app-notification__text">
                    {{ notification.text }}
                </div>
                <button
                    class="app-notification__remove-btn"
                    aria-label="Скрыть уведомление"
                    @click="() => removeNotification(notification)"
                >
                    <icon-cross></icon-cross>
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import { dispatcher } from '../../../inits/dispatcher';
import CrossIcon from '../svg/Cross.vue';

let id = 0;

export default {
    components: { 'icon-cross': CrossIcon },

    data() {
        return {
            notifications: [],
        };
    },

    created() {
        dispatcher.on('notification', (data) => this.addNotification(data.message));
    },

    methods: {
        addNotification(text) {
            const obj = {
                id: id++,
                text,
                timeout: setTimeout(() => this.removeNotification(obj), 3000),
            };

            this.notifications.unshift(obj);
        },

        removeNotification(obj) {
            this.notifications.splice(this.notifications.indexOf(obj), 1);
        },
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';
@import '../../../../css/utils/mixins';

.app-notifications-list {
    contain: content;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 700px;
    z-index: 1000;

    @media screen and (max-width: 767px) {
        width: calc(100% - var(--offset-x) * 2);
        max-width: 100%;
    }
}

.app-notification {
    padding: 10px 15px;
    border-radius: 16px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid $color-black-10;
    font-size: 14px;

    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }

    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }

    + .app-notification {
        margin-top: 5px;
    }
}

.app-notification__remove-btn {
    @include clickable();
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    flex-shrink: 0;
}
</style>
