import popups from './popups';

const popupName = 'catalog-menu';
let categoryLinks: HTMLElement[] = [];
let categoryContents: HTMLElement[] = [];

function init() {
    categoryContents = Array.from(document.querySelectorAll('.js-catalog-menu-content'));
    categoryLinks = Array.from(document.querySelectorAll('.js-catalog-block-list-link'));
    const popupEl = document.querySelector(`[data-lit-popup="${popupName}"]`);
    const popup = popups.getInstanceByElement(popupEl);
    const popupElOpenButtons = Array.from(document.querySelectorAll(`[data-lit-popup-open="${popupName}"]`));

    categoryLinks.forEach((link) => {
        link.addEventListener('mouseenter', () => {
            const { id } = link.dataset;
            const exactContent = categoryContents.find((el) => el.dataset.id === id);

            categoryLinks.forEach((el) => {
                el.classList.remove('active');
            });
            link.classList.add('active');
            categoryContents.forEach((el) => el.classList.remove('active'));
            exactContent?.classList.add('active');
        });
    });

    if (popup) {
        popup.on('open', () => {
            localStorage.setItem('currentScrollY', `${window.scrollY}`);
            window.scrollTo({ top: 0, behavior: 'auto' });

            setTimeout(() => {
                popupElOpenButtons.forEach((btn) => {
                    btn.removeAttribute('data-lit-popup-open');
                    btn.setAttribute('data-lit-popup-close', popupName);
                });
            }, 1);
        });

        popup.on('close', () => {
            const currentScroll = localStorage.getItem('currentScrollY');

            if (currentScroll) {
                window.scrollTo({ top: parseFloat(currentScroll), behavior: 'auto' });
                localStorage.removeItem('currentScrollY');
            }

            popupElOpenButtons.forEach((btn) => {
                btn.removeAttribute('data-lit-popup-close');
                btn.setAttribute('data-lit-popup-open', popupName);
            });
        });
    }

    Array.from(document.querySelectorAll<HTMLElement>('.js-catalog-menu-content-list--inner')).forEach((listEl) => {
        const listItems = (Array.from(listEl.children) as HTMLElement[]).filter((el) =>
            el.classList.contains('js-catalog-menu-content-list__item'),
        );

        if (listItems.length > 6) {
            let isHidden = true;
            const toggler = document.createElement('button');
            toggler.textContent = 'Ещё';
            toggler.className = 'catalog-menu-full-list-toggler';

            const extraListItems = listItems.slice(6);

            const expand = () => {
                extraListItems.forEach((el) => {
                    el.hidden = false;
                });
                toggler.textContent = 'Свернуть';
                toggler.classList.add('is-expanded');
            };

            const collapse = () => {
                extraListItems.forEach((el) => {
                    el.hidden = true;
                });
                toggler.textContent = 'Ещё';
                toggler.classList.remove('is-expanded');
            };

            if (isHidden) {
                collapse();
            } else {
                expand();
            }

            toggler.addEventListener('click', () => {
                isHidden = !isHidden;

                if (isHidden) {
                    collapse();
                } else {
                    expand();
                }
            });

            listEl.appendChild(toggler);
        }
    });

    const getColumnsAmount = () => {
        if (window.matchMedia('(max-width: 1154px)').matches) {
            return 2;
        }

        if (window.matchMedia('(max-width: 1440px)').matches) {
            return 3;
        }

        return 4;
    };

    /**
     * Колонки
     */
    function ss() {
        categoryContents.forEach((contentEl, index) => {
            // console.log('Starting content ', index + 1);
            const listEl = contentEl.querySelector('.js-catalog-menu-content-list--level-1');
            const catalogMenuItems = Array.from(
                contentEl.querySelectorAll('.js-catalog-menu-content-list__item--level-1'),
            );

            let totalHeight = 0;
            let currentMenuItemIndex = 0;
            const prevGeneratedColumns = Array.from(
                contentEl.querySelectorAll('.catalog-menu-content-list__item--level-1--generated'),
            );
            const prevGeneratedColumnsAmount = prevGeneratedColumns.length;

            categoryContents.forEach((el) => {
                el.style.display = 'none';
            });
            contentEl.style.display = 'block';

            // Считаем общую высоту всех элементов
            catalogMenuItems.forEach((el) => {
                totalHeight += el.getBoundingClientRect().height;
                el.classList.remove('catalog-menu-content-list__item--level-1');
            });

            // Получаем медианную (идеальную) высоту колонки
            const medianColumsHeight = totalHeight / getColumnsAmount();
            var columns = [];
            var heights = [];
            for (let i = 0; i < getColumnsAmount(); i += 1) {
                var currentHeight = 0;
                var previousHeight = currentHeight;
                let currentLocalIndex = 0;
                const columnEl = document.createElement('div');
                columnEl.className =
                    'catalog-menu-content-list__item--level-1 catalog-menu-content-list__item--level-1--generated';

                while (true) {
                    if (!catalogMenuItems[currentMenuItemIndex]) break;

                    const menuItemHeight = catalogMenuItems[currentMenuItemIndex].getBoundingClientRect().height;

                    heights.push(catalogMenuItems[currentMenuItemIndex].getBoundingClientRect().height);
                    currentHeight += menuItemHeight;
                    columnEl.appendChild(catalogMenuItems[currentMenuItemIndex]);
                    currentMenuItemIndex++;
                    currentLocalIndex++;

                    if (i === getColumnsAmount() - 1) {
                        // Последняя колонка
                        continue;
                    }

                    if (
                        currentHeight >= medianColumsHeight ||
                        currentLocalIndex >= Math.ceil(catalogMenuItems.length / getColumnsAmount())
                    ) {
                        break;
                    }
                }

                columns.push(columnEl);
            }
            /*let col_h=[];
            let indexz=-1;
            for (let i = 0; i < columns.length; i += 1) {
                let childs = columns[i].childNodes
                for (let z = 0; z < childs.length; z += 1) {
                    indexz+=1;
                    if(typeof col_h[i]=='undefined'){
                        col_h[i]=0;
                    }
                    col_h[i]+=heights[indexz];
                }
            }*/

            for (let i = 0; i < columns.length; i += 1) {
                /*for (let z = 0; z < columns.length; z+=1)
                {
                    let max_value = Math.max.apply(null, col_h);
                    if(max_value==0){
                        break;
                    }
                    if(col_h[z]==max_value){
                        col_h[z]=0;*/
                listEl?.appendChild(columns[i]);
                /*}
                }*/
            }

            for (let i = 0; i < prevGeneratedColumnsAmount; i += 1) {
                prevGeneratedColumns[i].parentElement?.removeChild(prevGeneratedColumns[i]);
            }
        });

        categoryContents.forEach((el) => {
            el.style.display = '';
        });
    }
    document.querySelector('.catalog-open-btn')?.addEventListener('click', ss);
    window.addEventListener('resize', ss);
    ss();
}

const _module = { init };

export default _module;
