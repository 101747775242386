import { fetchShuffle } from '../dynamic-modules';

const BUTTON_ACTIVE_CLASS = 'active';

let shuffles: any[] = [];

async function init(container: Element | Document = document) {
    const shuffleContainers = Array.from(container.querySelectorAll('.js-shuffle')) as HTMLElement[];

    if (shuffleContainers.length > 0) {
        const { default: Shuffle } = await fetchShuffle();

        Shuffle.ShuffleItem.Scale = {
            VISIBLE: 1,
            HIDDEN: 0.5,
        };

        shuffleContainers.forEach((el) => {
            let selectedFilter = '';

            const buttons = Array.from(
                document.querySelectorAll(`[data-shuffle-container-name="${el.dataset.shuffleName}"]`),
            ) as HTMLElement[];

            const shuffle = new Shuffle(el, {
                itemSelector: '.js-shuffle-item',
                sizer: '.js-shuffle-sizer',
                speed: 800,
                useTransforms: true,
                easing: 'cubic-bezier(0.83, 0, 0.17, 1)',
            });

            function onButtonClick(this: HTMLElement) {
                const filterValue = this.dataset.shuffleFilterGroup;

                if (!filterValue) {
                    throw new Error('[shuffle] "data-shuffle-filter-group" attribute must be set on the button');
                }

                if (this.classList.contains(BUTTON_ACTIVE_CLASS)) {
                    selectedFilter = Shuffle.ALL_ITEMS;
                } else {
                    selectedFilter = filterValue;
                    buttons
                        .filter((btn) => btn !== this)
                        .forEach((btn) => {
                            btn.classList.remove(BUTTON_ACTIVE_CLASS);
                        });
                }

                shuffle.filter(selectedFilter);
                this.classList.toggle(BUTTON_ACTIVE_CLASS);
            }

            buttons.forEach((btn) => {
                btn.addEventListener('click', onButtonClick);
            });

            shuffles.push(shuffle);
        });
    }
}

function destroy() {
    shuffles.forEach((shuffle) => shuffle.destroy());
    shuffles = [];
}

const _module = { init, destroy };

export default _module;
